import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { mongoId } from '../../../../@core/common/global';
import { ApiService } from '../../../../@core/service/api.service';
import { SharedDataService } from '../../../../@core/service/shared-data.service';
import { BusinessModel, ContactModel, EContactFieldType, EContactType } from '../../../../@models';
import { CompilationModel } from '../../../../@models/erp/compilation';
import { CompilationTypeData, DocConfig } from '../../../../@models/erp/compilationData';

export class ContactUtil  {
  data: SharedDataService;

  constructor(data: SharedDataService) {
    this.data = data;
  }

  getContactField(business: string | BusinessModel, field: EContactFieldType, contactType = EContactType.General) {
    const contacts = business && this.data.businesses.find(b => b._id === mongoId(business)).contacts;
    const contact = contacts?.find(c => c.type === contactType);
    const value = contact?.fields?.find(t => t.type === field)?.value;
    return value;
  }

  getGeneralContact(business: string | BusinessModel) {
    const contacts = business && this.data.businesses.find(b => b._id === mongoId(business)).contacts;
    return contacts && contacts.find(c => c.type === EContactType.General);
  }

  // eslint-disable-next-line max-len
  getAddressFromBusiness(business: string, fields = DocConfig.get('defaultAddress').fields, type: EContactType = EContactType.General, fallbackToGeneral = true) {
    const contacts = business && this.data.businesses.find(b => b._id === mongoId(business))?.contacts;
    const contact = contacts?.find(c => c.type === type) || (fallbackToGeneral && contacts?.find(c => c.type === EContactType.General));
    // console.log('contacts', business, contacts, contact)
    return contact ? this.getAddress(contact, fields) : undefined;
  }

  getAddress(contact: ContactModel, fields = DocConfig.get('defaultAddress').fields) {
    let address = '';
    contact && fields.forEach(el => {
      const label = el['label'] || '';
      const spacing = el['spacing'] ? '\n'.repeat(el['spacing']) : '';
      if (el['title']) {
        address += label + (contact.addressName || contact.title) + (el['nobreak'] ? ' ' : '\n') + spacing;
      } else {
        const field = contact.fields && contact.fields.find(f => +f.type === +el['field']);
        if (field) address += !el['types'] || el['types']?.includes(contact.type) ? label + field.value + (el['nobreak'] ? ' ' : '\n') + spacing : '';
      }
    });
    return address.trim();
  }

  // eslint-disable-next-line max-len
  getContactInfoFromBusiness(business: string, fields: /* from DocConfig */ any[], type: EContactType = EContactType.General) {
    const contacts = business && this.data.businesses.find(b => b._id === mongoId(business)).contacts;
    const contact = contacts
      && (contacts.find(c => c.type === +type) || contacts.find(c => c.type === EContactType.General));

    // console.log('getContactInfoFromBusiness', business, contacts, contact);
    if (contact) return this.getContactInfo(contact, fields);
  }

  getContactInfo(contact: ContactModel, fields: /* from DocConfig */ any[]) {
    let address = '';
    let hasPhoneNumber = false;
    contact && fields.forEach(el => {
      const label = el['label'] || '';
      const spacing = el['spacing'] ? '\n'.repeat(el['spacing']) : '';
      if (el['title']) {
        address += label + contact.title + (el['nobreak'] ? ' ' : '\n') + spacing;
      } else {
        // eslint-disable-next-line eqeqeq
        const field = contact.fields && contact.fields.find(f => f.type == el['field']);
        // console.log('has phone number', field, address, hasPhoneNumber)
        if (field && [EContactFieldType.Mobile, EContactFieldType.BusinessLandline, EContactFieldType.Phone].includes(el['field'])) {
          if (!hasPhoneNumber) address += label + field.value + (el['nobreak'] ? ' ' : '\n') + spacing;
          hasPhoneNumber = true;
        } else if (field) {
          address += label + field.value + (el['nobreak'] ? ' ' : '\n') + spacing;
        }
      }
    });
    return address.trim();
  }

}

@Component({
  selector: 'ngx-doc-form-address-selector',
  templateUrl: './doc-form-address-selector.component.html',
  styleUrls: ['./doc-form-address-selector.component.scss'],
})
export class DocFormAddressSelectorComponent implements OnChanges {

  @Input() compilation: CompilationModel;
  @Input() businesses: BusinessModel[];
  @Input() contactType: EContactType = EContactType.PickUp;
  @Input() business: BusinessModel = null;
  @Input() contacts: ContactModel[];
  @Input() contactField: string = 'recipientContact';
  @Input() disabled: boolean = false;
  @Input() border: boolean = true;
  @Input() label: string;
  @Input() labelAlign: string = 'top';
  @Input() address: string;
  @Input() address2: string;
  @Input() clearBusiness: boolean = false;
  @Input() additionalAddresses: BusinessModel[];
  @Input() invoiceSendTo: boolean = false;
  @Output() businessChange: EventEmitter<BusinessModel> = new EventEmitter<BusinessModel>();
  @Output() contactChange: EventEmitter<ContactModel> = new EventEmitter<ContactModel>();
  @Output() addressChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() address2Change: EventEmitter<string> = new EventEmitter<string>();

  addresses: ContactModel[];
  addresses2: ContactModel[];
  addressOptions: { id: string, name: string, address: string }[];
  addressOptionId: string  = null;
  addressOptions2: { id: string, name: string, address: string }[];
  addressOptionId2: string  = null;
  businessOptions: { id: string, name: string }[];
  // contact: ContactModel = null;
  contactOptions: { id: string, name: string, contact: string }[];
  contactOptionId: string = null;

  showInvoiceSendTo = false;

  contactUtil: ContactUtil;

  constructor(private api: ApiService, private data: SharedDataService) { }

  checkVisible = (section: string, type: number): boolean => DocConfig.checkVisible(section, type);
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.businesses && this.businesses) {
      this.businessOptions = this.businesses.map(m => m && ({ id: m._id, name: m.name }));
    }
    if (changes.business) {
      if (!this.contactUtil) this.contactUtil = new ContactUtil(this.data);
      this.businessChanged(changes.business.currentValue, changes.business);
    }
    // console.log('visible fields:', this.compilation?.visibleFields);
    this.showInvoiceSendTo = this.compilation?.visibleFields?.includes('invoiceSendTo')
  }

  getAddresses(business: BusinessModel) {
    // const subscription = this.api.loadBusiness(mongoId(business)).subscribe(_business => {
    const _business = this.data.businesses.find(f => mongoId(f) === mongoId(business));
    if (!_business) return;

    this.addresses = _business.contacts.filter(f =>
      f.type === EContactType.Delivery || f.type === EContactType.Invoice
      || f.type === EContactType.Legal || f.type === EContactType.PickUp
      || f.type === EContactType.General
    );
    this.addresses2 = _business.contacts.filter(f => f.type === EContactType.InvoiceSendTo);

    if (this.additionalAddresses) {
      for (const item of this.additionalAddresses) {
        const bs = this.data.businesses.find(f => mongoId(f) === mongoId(item)); // this.api.getBusiness(mongoId(item));
        this.addresses.unshift(bs.contacts.find(f => f.type === EContactType.General));
      }
    }

    this.addressOptions = [{ id: null, name: 'Select address', address: '' }, ...this.addresses.map(m => {
      const type = ['', 'General', 'Legal', 'Invoice', 'Delivery', 'Employee', 'Pick up', 'Ex employee'][m.type];
      return ({ id: m._id, name: `${m.title} (${type})`, address: this.contactUtil.getAddress(m, DocConfig.get('defaultAddress').fields) })
    })];
    this.addressOptions2 = [{ id: null, name: 'Select address', address: '' }, ...this.addresses2.map(m => {
      return ({ id: m._id, name: `${m.title}`, address: this.contactUtil.getAddress(m, DocConfig.get('invoiceSendToAddress').fields) })
    })]

    this.addressOptionId = (this.addressOptions.find(f => 
      f.address && this.address
      && f.address.replace(/\s+/g, '') === this.address.replace(/Attn: [^\n]+/, '').replace(/\s+/g, '')
    ) || {} as any).id || null;
    this.addressOptionId2 = (this.addressOptions2.find(f =>
      f.address && this.address2
      && f.address.replace(/\s+/g, '') === this.address2.replace(/\s+/g, '')
    ) || {} as any).id || null;

    // console.log('after get addr', this.addresses )
    // subscription.unsubscribe()
    // });
  }

  clearFields() {
    this.address = null;
    this.address2 = null;
    this.addresses = null;
    this.addresses2 = null;
    this.addressOptionId = null;
    this.addressOptionId2 = null;
    this.contactOptionId = null;
    if (this.compilation && this.compilation[this.contactField]) this.compilation[this.contactField] = null;
    this.addressOptions = [];
    this.addressOptions2 = [];
    this.contactOptions = [];
    if (this.compilation && this.compilation[this.contactField]) {
      this.compilation[this.contactField] = null;
    }
  }

  async businessChanged(business: BusinessModel, changes = { firstChange: false }) {
    if (!business) this.clearFields();
    // if (!changes.firstChange) {
    //   this.address = null;
    //   this.addresses = null;
    //   this.addressOptionId = null;
    //   this.contactOptionId = null;
    //   if (this.compilation && this.compilation[this.contactField]) this.compilation[this.contactField] = null;
    //   this.addressOptions = [];
    //   this.contactOptions = [];
    //   if (!business) return;
    // }
    this.business = business;
    await this.getAddresses(business);
    // this.address = null;
    // this.contact = null;
    this.reloadContacts();
    // if (!changes.firstChange && this.compilation && this.compilation[this.contactField]) {
    //   this.compilation[this.contactField] = null;
    // }
    this.businessChange.emit(business);
    if (this.clearBusiness) this.business = null;
  }

  addressSelectionChanged(contact: ContactModel) {
    const _contact = this.addresses.find(f => f._id === mongoId(contact));
    this.address = this.addressOptions.find(f => f.id === mongoId(contact))?.address;
    // this.contactUtil.getAddress(_contact, DocConfig.get('defaultAddress').fields);
    this.contactChange.emit(_contact);
    this.addressChange.emit(this.address);
  }

  addressSelectionChanged2(contact: ContactModel) {
    const _contact = this.addresses2.find(f => f._id === mongoId(contact));
    this.address2 = this.addressOptions2.find(f => f.id === mongoId(contact)).address;
    this.address2Change.emit(this.address2);
    // console.log('addressSelectionChanged2', this.addressOptions2, this.address2)
  }

  reloadContacts() {
    // const subscr = this.api.loadBusiness(mongoId(this.business)).subscribe(business => {
      const business = this.data.businesses.find(f => mongoId(f) === mongoId(this.business));
      if (!business) return;
      this.contacts = business.contacts;
      this.contactOptions = [{ id: null, name: 'Select contact', contact: '' }, ...this.contacts
        .filter(f => f.type === EContactType.Employee)
        .map(m => ({ id: m._id, name: m.title, contact: this.contactUtil.getContactInfo(m, DocConfig.get('recipientContact').fields) }))];
      if (this.compilation) {
        this.contactOptionId = (this.contactOptions.find(f =>
          f.contact && this.compilation[this.contactField]
          && f.contact.replace(/\s+/g, '') === this.compilation[this.contactField].replace(/\s+/g, '')
        ) || {} as any).id || null;
      }
      // subscr.unsubscribe();
    // });
  }

  contactSelectionChanged(contact: string) {
    // const _contact = this.contacts.find(f => f._id === mongoId(contact));
    this.compilation[this.contactField] = this.contactOptions.find(f => f.id === mongoId(contact)).contact;
    // = this.contactUtil.getContactInfo(_contact, DocConfig.get('recipientContact').fields);
    if (this.hasAttn()) {
      this.attentionToChanged(false);
      this.attentionToChanged(true);
    }
  }

  showInvoiceSendToChanged(show: boolean) {
    this.compilation.visibleFields = this.compilation.visibleFields.filter(f => f !== 'invoiceSendTo');
    if (show) this.compilation.visibleFields.push('invoiceSendTo');
  }

  hasAttn() {
    return /Attn: /.test(this.address)
  }

  attentionToChanged(show: boolean) {
    if (show) {
      const contact = 'Attn: ' + this.compilation[this.contactField]?.split('\n')?.slice(0,1);
      const lines = this.address.split('\n');
      lines.splice(1, 0, contact);
      this.address = lines.join('\n');
    } else {
      const lines = this.address.split('\n');
      lines.splice(lines.findIndex(f => /Attn: /.test(f)), 1);
      this.address = lines.join('\n');
    }
    this.addressChange.emit(this.address);
  }

}
