import { AfterViewInit, Component, ElementRef, isDevMode, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import {
  NbDialogService,
  NbMediaBreakpoint,
  NbMediaBreakpointsService,
  NbMenuItem,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from '@nebular/theme';

import { StateService } from '../../../@core/data/state.service';

import { Subscription } from 'rxjs';
import { withLatestFrom, delay } from 'rxjs/operators';
import { SocketService } from '../../../@core/service/socket.service';
import { ApiService } from '../../../@core/service/api.service';

import { UAParser} from 'ua-parser-js';
import { AuthGuardService } from '../../../@core/service/auth-guard.service';
import { CommonDialogComponent, NrkDialogType } from '../../../pages/erp/shared/common-dialog/common-dialog.component';
import { TabCoordinatorService } from '../../../@core/service/tab-coordinator.service';
import { EventService } from '../../../@core/service/eventService';
import { style } from '@angular/animations';
import * as moment from 'moment';

// TODO: move layouts into the frameworkm
@Component({
  selector: 'ngx-sample-layout',
  styleUrls: ['./sample.layout.scss'],
  template: `
    <div style="margin:30px" *ngIf="errString || !appVisible">{{errString}}</div>
    <nb-layout [center]="layout.id === 'center-column'" windowMode *ngIf="appVisible">
      <nb-layout-header fixed id="layout-header">
        <ngx-header [position]="sidebar.id === 'left' ? 'normal': 'inverse'"></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar"
                   tag="menu-sidebar"
                   responsive
                   [right]="sidebar.id === 'right'"
                   id="layout-sidebar"
                   >
        <nb-sidebar-header>
        </nb-sidebar-header>
        <ng-content select="nb-menu"></ng-content>
        <nb-sidebar-footer style="height:4.5em">
          <table style="margin-bottom:1em"><tr>
            <td style="width: 100%">v{{that.version}}</td>
            <td *ngIf="feedback">
              <a [ngStyle]="{color: this.tabCoordinator.isMasterTab ? 'red' : '#ddd'}" href="https://feedback.nrk.rebus.link" target="blank">feedback</a>
              <div [ngStyle]="{color: this.tabCoordinator.isMasterTab ? 'red' : '#ddd'}" class="debugreport" (click)="bugreport()">debugreport</div>
            </td></tr>
          </table>
        </nb-sidebar-footer>
      </nb-sidebar>

      <nb-layout-column class="main-content">
        <ng-content select="router-outlet"></ng-content>
        <ngx-status-window [show]="statusVisible" [message]="statusMsg"
          [value]="statusVal" [log]="statusLog"></ngx-status-window>
      </nb-layout-column>

      <!--<nb-layout-column left class="small" *ngIf="layout.id === 'two-column' || layout.id === 'three-column'">
        <nb-menu [items]="getMenu()"></nb-menu>
      </nb-layout-column>http://localhost:4200/#/pages/erp/document/nrka?code=OCNRKA240109
        <nb-menu [items]="getMenu()"></nb-menu>
      </nb-layout-column>-->


      <nb-sidebar class="settings-sidebar"
                   tag="settings-sidebar"
                   state="collapsed"
                   fixed
                   [right]="sidebar.id !== 'right'">
        <ngx-theme-settings></ngx-theme-settings>
      </nb-sidebar>
    </nb-layout>
  `,
})
export class SampleLayoutComponent  implements OnInit, OnDestroy, AfterViewInit {

  layout: any = {};
  sidebar: any = {};

  protected layoutState$: Subscription;
  protected sidebarState$: Subscription;
  protected menuClick$: Subscription;

  minScreenSize = { w:1260, h:750 };
  appVisible = true;
  errString = null;

  public static version = '0.6.150';

  that = SampleLayoutComponent;

  subscriptions = [];

  constructor(protected stateService: StateService,
              protected menuService: NbMenuService,
              protected themeService: NbThemeService,
              protected bpService: NbMediaBreakpointsService,
              protected sidebarService: NbSidebarService,
              protected api: ApiService,
              protected auth: AuthGuardService,
              protected tabCoordinator: TabCoordinatorService,
              protected dialogService: NbDialogService,
              private event: EventService,
              protected socket: SocketService) {
    this.layoutState$ = this.stateService.onLayoutState()
      .subscribe((layout: string) => this.layout = layout);

    this.sidebarState$ = this.stateService.onSidebarState()
      .subscribe((sidebar: string) => {
        this.sidebar = sidebar;
      });

    const isBp = this.bpService.getByName('is');
    // this.menuClick$ = this.menuService.onItemSelect()
    //   .withLatestFrom(this.themeService.onMediaQueryChange())
    //   .delay(20)
    //   .subscribe(([item, [bpFrom, bpTo]]: [any, [NbMediaBreakpoint, NbMediaBreakpoint]]) => {

    //     if (bpTo.width <= isBp.width) {
    //       this.sidebarService.collapse('menu-sidebar');
    //     }
    //   });
    this.menuClick$ = this.menuService.onItemSelect()
      .pipe(withLatestFrom(this.themeService.onMediaQueryChange()), delay(20))
      .subscribe(([item, [bpFrom, bpTo]]: [any, [NbMediaBreakpoint, NbMediaBreakpoint]]) => {
        if (bpTo.width <= isBp.width) {
          this.sidebarService.collapse('menu-sidebar');
        }
      });

    this.subscriptions.push(
      this.event.receiver.subscribe(event => {
        switch (event.name) {
          case 'tab-coordinator':
            if (event.data === 'queueChecker') this.evalBrowserTabState();
            break;
        }
      })
    );

  }

  private evalBrowserTabState() {
    // console.log('evalBrowserTabState', this.tabCoordinator.isMasterTab);
    if (!this.tabCoordinator.isMasterTab) {
      document.getElementById('layout-header').style.backgroundColor = '#755';
      document.getElementById('layout-sidebar').style.backgroundColor = '#755';
      // Array.from(document.getElementsByClassName('menu-item')).forEach(el => {
      //   el.setAttribute('style', 'border-bottom:solid 1px #999');
      // });
    } else {
      document.getElementById('layout-header').style.backgroundColor = this.originalBackgroundColor;
      document.getElementById('layout-sidebar').style.backgroundColor = this.originalBackgroundColor;
      // Array.from(document.getElementsByClassName('menu-item')).forEach(el => {
      //   el.setAttribute('style', `border-bottom: solid 1px ${this.originalBackgroundBorder}`);
      // });
    }
  }

  private originalBackgroundColor: string;
  // private originalBackgroundBorder: string;
  ngAfterViewInit(): void {
    this.originalBackgroundColor = document.getElementById('layout-header').style.backgroundColor;
    // this.originalBackgroundBorder = '#ca345d'
    this.evalBrowserTabState();
  }

  statusMsg: string = '';
  statusVal: number = 0;
  statusVisible: boolean = false;
  statusLog: string[] = [];
  statusDontClose = false;
  feedback: boolean = false;

  ngOnInit(): void {
    this.socket.progress.openProgress.subscribe(id => {
      this.statusLog = [];
      this.statusVisible = true;
      this.statusDontClose = false;
    })
    this.socket.progress.showProgress.subscribe(data => {
      this.statusMsg = data.text;
      this.statusVal = data.value;
    })
    this.socket.progress.closeProgress.subscribe(id => {
      if (!this.statusDontClose) this.statusVisible = false;
      this.statusMsg = '';
      this.statusVal = 0;
    })
    this.socket.progress.addProgressMessage.subscribe(data => {
      this.statusDontClose = true;
      this.statusLog.push(data.message);
    })
    this.auth.loadMe().then(me => {
      this.feedback =
        me.username.includes('loiz') ||
        me.username.includes('lechner') ||
        me.username.includes('schunko') ||
        me.username.includes('kurita') ||
        me.username.includes('timoransky') ||
        me.username.includes('hrehus');
    });

    const ua = UAParser();
    const device = ua.device.type;
    if (['console', 'mobile', 'tablet', 'smarttv', 'wearable', 'embedded'].includes(device)) {
      this.appVisible = false;
      this.errString = 'Nipponrika CRM is disabled on ' + device;
    }
    if (!this.auth.user.erpRoles.testServerAccess && isDevMode()) {
      this.appVisible = false;
      this.errString = 'Access denied: You don\'t have access to the test server';
    }

  }

  ngOnDestroy() {
    this.layoutState$.unsubscribe();
    this.sidebarState$.unsubscribe();
    this.menuClick$.unsubscribe();
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  bugreport() {

    const log = (console as any).errors;

    log.forEach(el => {
      el.data = el.data.map(m => {
        if (m === null || m === undefined) return m;
        switch (typeof m) {
          case 'object':
            if (el.type === 'err') {
              if (m.message && m.stack) return { message: m.message, stack: m.stack }
            }
            return 'object';
          case 'number':
          case 'string':
            return m;
          default:
            return Array.isArray(m) ? 'array' : 'object...'
        }
      })
    });
    // (console as any).defaultLog(JSON.stringify(log));

    this.dialogService.open(CommonDialogComponent, {
      context: {
        dialogType: NrkDialogType.bugReport,
        debugPayload: JSON.stringify(log),
      },
    }).onClose.subscribe(async x => {
      if (x.description) {
        // const ret = {
        //   time: new Date().toISOString(),
        //   user: this.auth.user.username,
        //   text: x.description,
        //   item: log,
        // }
        // this.api.sendBugReport(JSON.stringify(ret));
        this.api.debugConsoleLog(x.description);
      }
    });
  }

  // @HostListener('window:resize', ['$event'])
  // onResize(event) {
  //   this.appVisible = event.target.innerWidth > 1250 && event.target.innerHeight > 750;
  //   console.log('valid screen size', this.appVisible, event.target.innerWidth, event.target.innerHeight);
  //   const ua = new UAParser();
  //   console.log(ua.getResult(), ua.getDevice());
  // }

  // test() {
  //   const ua = new UAParser();
  //   return JSON.stringify(ua.getResult(), null, 2) + 'w: ' + window.innerWidth + ', h:' + window.innerHeight;
  // }
}
