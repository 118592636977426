<div [ngClass]="[labelAlign == 'left' ? 'left-label' : 'top-label', border ? 'address-box' : '']">
  <li>
    <label><i>{{label}}</i></label>
    <ngx-doc-form-filter-select [options]="businessOptions" [value]="$any(business)" (valueChange)="$any($event)" placeholder="Select business"
      (valueChange)="businessChanged($any($event));clearFields()" [disabled]="disabled"></ngx-doc-form-filter-select>
  </li>
  <li>
    <label>&nbsp;</label>
    <!-- <ngx-doc-form-dropdown class="fullwidth" [value]="addressOptions && addressOptions[0] && addressOptions[0].id" [options]="addressOptions" placeholder="Select Address"
      (valueChange)="addressSelectionChanged($event)" [disabled]="disabled"> -->
    <ngx-doc-form-dropdown class="fullwidth" [(value)]="addressOptionId" [options]="addressOptions" placeholder="Select Address"
      (valueChange)="addressSelectionChanged($event)" [disabled]="disabled">
    </ngx-doc-form-dropdown>
  </li>
  <li>
    <label>&nbsp;</label>
    <ngx-doc-form-textarea class="fullwidth" rows="6" [placeholder]="label"
      [(value)]="address" [disabled]="disabled" (valueChange)="addressChange.emit($event)"></ngx-doc-form-textarea>
  </li>

  <!-- invoice send to -->
  <ng-container *ngIf="invoiceSendTo && checkVisible('invoiceSendTo', compilation.type)">
    <li>
      <label>Invoice send to</label>
      <ngx-doc-form-dropdown class="fullwidth" [(value)]="addressOptionId2" [options]="addressOptions2" placeholder="Select Address"
        (valueChange)="addressSelectionChanged2($event)" [disabled]="disabled">
      </ngx-doc-form-dropdown>
    </li>
    <li>
      <label><input type="checkbox" [(ngModel)]="showInvoiceSendTo" (ngModelChange)="showInvoiceSendToChanged($event)" title="show in PDF"></label>
      <ngx-doc-form-textarea class="fullwidth" rows="6" [placeholder]="label"
        [(value)]="address2" [disabled]="disabled" (valueChange)="address2Change.emit($event)"></ngx-doc-form-textarea>
    </li>
  </ng-container>

  <ng-content></ng-content>

  <ng-container *ngIf="compilation && contactField">
    <li>
      <label>Contact</label>
      <div>
        <div class="d-inline-block" style="width:19.5em">
          <ngx-doc-form-dropdown class="fullwidth" [(value)]="contactOptionId" [options]="contactOptions" placeholder="Contact"
            (valueChange)="contactSelectionChanged($event)" [disabled]="disabled">
          </ngx-doc-form-dropdown>
        </div>
        <div *ngIf="!disabled" class="d-inline-block ml-1"><button class="nrk-doc-button" (click)="compilation[contactField]=null">X</button></div>
      </div>
    </li>
    <li>
      <label>
        Contact Info<br>
        Attn <input type="checkbox" [ngModel]="hasAttn()" (ngModelChange)="attentionToChanged($event)" title="show contact name in address field">
      </label>
      <ngx-doc-form-textarea class="fullwidth" rows="6" placeholder="Contact"
        [(value)]="compilation[contactField]" [disabled]="disabled"></ngx-doc-form-textarea>
    </li>
  </ng-container>
</div>

