import { MaterialModel, FileModel, BusinessUnitModel } from '..';
import { ChangelogEntry } from '../../pages/erp/shared/changelog/changelog.component';
import { BusinessField } from '../business';
import { UserModel } from '../user';

export enum DownloadType {
  document = 1,
  merged,
  zipMerged,
  zipAttachments,
  pdfAndZip,
  preview,
}
export class BaseProductStatus {
  private static data = [
    { id: 1, name: 'Sample Product' },
    { id: 2, name: 'Commercial Product' },
    { id: 3, name: 'Obsolete Product' },
    { id: 4, name: 'Not existing' },
    { id: 5, name: 'Template' },
  ]
  static getOptions = () => BaseProductStatus.data;
}
export enum EBaseProductStatus {
  sampleProduct = 1,
  commercialProduct = 2,
  obsoleteProduct = 3,
  notExisting = 4,
  template = 5,
}

export class BaseProductModel {
  public _id?: string;

  public name: string;
  public thickness: string;
  public trial: string;
  public status: number;
  public weight: number; // totalAreaWeight
  public businessUnit: BusinessUnitModel;
  public material: MaterialModel;
  public customer: BusinessField;
  public supplier: BusinessField;
  public remark: string;
  public binderContent: number;
  public micaPaper: number;
  public grammatureMica: number;
  public accelContent: number;
  public accelType: number;
  public code: string;
  public version: number;
  public files: FileModel[];
  public technicalDatasheets: FileModel[];
  public draft: boolean;
  public changelog: string | ChangelogEntry[];
  public editCount: number = 0;
  public owner: UserModel;
  public shelfLife: string; // legacy
  public shelfLife20Deg: number;
  public shelfLife10Deg: number;
  public shelfLife5Deg: number;
  public shelfLifeFromDispatch20Deg: number;
  public shelfLifeFromDispatch10Deg: number;
  public shelfLifeFromDispatch5Deg: number;
  public shelfLifeMinInternal20Deg: number;
  public shelfLifeMinInternal10Deg: number;
  public shelfLifeMinInternal5Deg: number;
  public disabled: boolean = false;

  /// / legacy
  public dateCreated: Date;
  public dateUpdated: Date;
  public createdAt: Date;
  public updatedAt: Date;
}
