/* eslint-disable max-len */
import { EContactType } from '..';
import { IDropdownOptions } from '../../@core/service/shared-data.service';
import { Changelog } from '../../pages/erp/shared/changelog/changelog.component';
import { BusinessUnitModel } from '../businessUnit';
import { EContactFieldType } from '../contact';
import { CompilationModel } from './compilation';
import { EBoxLabelPriority, EProductType } from './finishedProduct';

// tslint:disable: max-line-length

export class CompilationDataModel {
  public meansOfTransportStore: string[];
  public termsOfPaymentStore: string[];
  public taxHintStore: string[];
  public paymentTextStore: string[];
  public displayDatesStore: string[];
  public checklist: { id: string, name: string, data: string[] }[];
  public changelog: { id: string, name: string, data: string[] }[];
  public fileDescriptions: { id: string, name: string, data: string[] }[];
  public draftNumbers: { type: number, year: number, count: number }[];
  public currency: { validFrom: Date, jpy: number, usd: number }[];
  public hsCode: { type: number, code: string, text: string }[];
  public paymentStore: { id: string, name: string, data: any[] }[];
  public packingStore: { id: string, name: string, data: any[] }[];
  public reportPresetsStore: { id: string, name: string, data: any[] }[];
  public staticTexts: { id: string, name: string, data: string }[];

  getDraftNumber = (type: number) => {
    const dn = this.draftNumbers.find(f => f.type === type);
    return dn && dn.count || 0;
  }
  setDraftNumber = (type: number, count: number) => {
    const dn = this.draftNumbers.find(f => f.type === type);
    if (dn) dn.count = count; else this.draftNumbers.push({ type, year: new Date().getFullYear(), count });
  }
  getYear = (type: number) => {
    const dn = this.draftNumbers.find(f => f.type === type);
    return dn && dn.year;
  }
  setYear = (type: number) => {
    const dn = this.draftNumbers.find(f => f.type === type);
    if (dn) dn.year = new Date().getFullYear(); else this.draftNumbers.push({ type, year: new Date().getFullYear(), count: 1 });
  }
}

/// /////////////////////////////////////////////// hardcoded table data from access db //////////////////////////////////////////////////////////

export class UnitData {
  private static data = [
    { id: 1, name: 'Pieces',            short: 'pc.',   price: 'price/unit' },
    { id: 4, name: 'Squaremeters',      short: 'm²',    price: 'price/m²' },
    { id: 2, name: 'Linearmeters',      short: 'm',     price: 'price/m' },
    { id: 3, name: '100 Linearmeters',  short: '100m',  price: 'price/100m'  },
    { id: 5, name: 'Kilogram',          short: 'kg',    price: 'price/kg' },
    // { id: 6, name: 'Rolls',             short: 'rolls' },
    { id: 7, name: 'Liter',             short: 'ℓ',     price: 'price/ℓ' },
  ]
  static get(id: number) {
    return UnitData.data.find(s => s.id === id)
  }
  static getFromShort(short: string) {
    return UnitData.data.find(f => f.short === short);
  }
  static getOptions() {
    return UnitData.data.map(i => ({ id: i.id, name: i.name }));
  }
  static getShortOptions() {
    return UnitData.data.map(i => ({ id: i.id, name: i.short }));
  }
  static getPriceOptions() {
    return UnitData.data.map(i => ({ id: i.id, name: i.price }));
  }
}

export class VatPercentData {
  private static data = [
    { id: 0, name: 0 },
    { id: 16, name: 16 },
    { id: 19, name: 19 },
    { id: 20, name: 20 },
  ]
  static getOptions() {
    return VatPercentData.data;
  }
}
export class InvoiceStatusData { // TODO: combine with PaymentStatusData
  private static data = [
    { id: 1, code: 'unpaid', name: 'not paid' },
    { id: 2, code: 'partially', name: 'partially paid' },
    { id: 3, code: 'full', name: 'fully paid' },
    { id: 4, code: 'notCollectible', name: 'not collectible' },
  ]
  static get(idOrCode: any) {
    return InvoiceStatusData.data.find(typeof idOrCode === 'number' ? f => f.id === idOrCode : f => f.code === idOrCode);
  }
  static getOptions() {
    return InvoiceStatusData.data.map(m => ({ id: m.id, name: m.name }));
  }
}
export class Currency {
  private static data = [
    { code: 'EUR', sign: '€' },
    { code: 'JPY', sign: '¥' },
    { code: 'USD', sign: '$' },
  ]
  // static getOptions() {
  //   return Currency.data.map(i => ({ id: i.code, name: i.code + ' (' + i.sign + ')' }));
  // }
  static getDefault() {
    return Currency.data[0];
  }
  static getTableOptions() {
    return Currency.data.map(i => ({ value: i.code, title: i.code + ' (' + i.sign + ')' }));
  }
  static getOptions() {
    return Currency.data.map(i => ({ id: i.code, name: i.code + ' (' + i.sign + ')' }));
  }
}

export class MicaPaper {
  private static data = [
    { id: 9, name: 'AM' },
    { id: 10, name: 'AMF' },
    { id: 3, name: 'KM' },
    { id: 2, name: 'KMF' },
    { id: 7, name: 'KMG' },
    { id: 4, name: 'KMP' },
    { id: 11, name: 'KRM' },
    { id: 12, name: 'KRMG' },
    { id: 6, name: 'RM' },
    { id: 1, name: 'RMF' },
    { id: 5, name: 'RMG' },
    { id: 8, name: 'Splitting' },
  ]
  static get (id: number) {
    return MicaPaper.data.find(s => s.id === id)
  }
  static getOptions() {
    return MicaPaper.data.map( i => ({ id: i.id, name: i.name }));
  }
}

// N, HD, H, HND, HO, Hi
export class AcceleratorType {
  private static data = [
    { id: 1, name: 'N' },
    { id: 2, name: 'HD' },
    { id: 3, name: 'H' },
    { id: 4, name: 'HND' },
    { id: 5, name: 'HO' },
    { id: 6, name: 'HI' },
    { id: 7, name: 'HZ' },
  ]
  static get (id: number) {
    return AcceleratorType.data.find(s => s.id === id)
  }
  static getOptions() {
    return AcceleratorType.data.map( i => ({ id: i.id, name: i.name }));
  }
}

// export class HsCode {
//   private static data = [
//     { id: 0, name: '' },
//     { id: 1, type: EProductType.Energy, code: '3919.90', text: '3919.90 (PAT 100 Tapes)' },
//     // { id: 2, name: '580632' },
//     // { id: 3, name: '641410' },
//     // { id: 4, name: '641810' },
//     // { id: 5, name: '681410' },
//     { id: 6, type: EProductType.Energy, code: '68141000', text: '68141000 (MICA Tapes)' },
//     // { id: 7, name: '701951' },
//     // { id: 8, name: '7410.21.00' },
//     // { id: 9, name: '7410.21.00.600' },
//     // { id: 10, name: '74102100' },
//     // { id: 11, name: '823723' },
//     { id: 12, type: EProductType.Electro, code: '84622180', text: '84622180 (CFM, TBFM, HBFM)'},
//     { id: 13, type: EProductType.Electro, code: '84622998', text: '84622998 (PCFM)'},
//     { id: 14, type: EProductType.Electro, code: '84669400', text: '84669400 (Spare Parts)'},
//     // { id: 15, name: '8503.60.000' },
//     // { id: 16, name: '85030090' },
//     { id: 17, type: EProductType.Electro, code: '85444995', text: '85444995 (Insulated wire, cable and other insulated electric conductors, optical fibre cables)'},
//   ];
//   static getOptions(type: EProductType) {
//     return [HsCode.data[0], ...HsCode.data.filter(f => (f.type === type))
//       .map(i => ({ id: i.id, code: i.code, name: i.text }))]
//   }
//   static get(idOrCode: any) {
//     return this.data.find(typeof idOrCode === 'number' ? f => f.id === idOrCode : f => f.code === idOrCode);
//   }
// }

export class CoreColor {
  private static data = [
    { id: 'R', name: 'Red' },
    { id: 'G', name: 'Green' },
    { id: 'T', name: 'Transparent' },
    { id: 'C', name: 'Carton' },
  ];
  static getOptions() {
    return CoreColor.data.map(m => ({ value: m.id, title: m.name }));
  }
}

export class DocumentStatusData {
  private static data = [
    { id: 1, name: 'draft' },
    { id: 2, name: 'final' },
    { id: 3, name: 'cancelled' },
    { id: 4, name: 'revised' },
  ]
  static get(id: number | string) {
    return this.data.find(typeof id === 'number' ? f => f.id === id : f => f.name === id);
  }
  static getOptions(withDraft: boolean = true) {
    return DocumentStatusData.data.filter(f => withDraft || f.id !== 1);
  }
}

export class PackingStatusData {
  private static data = [
    { id: 0, name: 'notReady' },
    { id: 1, name: 'packingReady' },
    { id: 2, name: 'dispatchReady' },
    { id: 3, name: 'dispatched' },
    { id: 4, name: 'delivered' },
    { id: 5, name: 'completed' },
  ]
  static get(id: number | string) {
    return this.data.find(typeof id === 'number' ? f => f.id === id : f => f.name === id);
  }
  static color(status: number) {
    return ['#ddd','#fb3c','#0afa','#0c0a','#0f0a','#eee'][status || 0] 
  }
  static text(status: number) {
    return ['Not Ready for Packing','Ready for Packing','Ready for Dispatch','Dispatched','Delivered','Completed'][status || 0] 
  }
}
// export function getDnStatusColor (packingStatus) { 
//   return ['#ddd','#fb3c','#0afa','#0c0a','#0f0a','#eee'][packingStatus || 0] 
// }
// export function getDnStatusText (packingStatus) { 
//   return ['Not Ready for Packing','Ready for Packing','Ready for Dispatch','Dispatched','Delivered','Completed'][packingStatus || 0] 
// }

export class BoxLabelPriorityData {
  static getText(blp: EBoxLabelPriority) {
    switch(blp) {
      case EBoxLabelPriority.Standard20Deg: return 'Standard Shelf Life 20°C';
      case EBoxLabelPriority.Standard10Deg: return 'Standard Shelf Life 10°C';
      case EBoxLabelPriority.Standard5Deg: return 'Standard Shelf Life 5°C';
      case EBoxLabelPriority.ExpiryColumn20Deg: return 'Expiry Column Shelf Life 20°C';
      case EBoxLabelPriority.ExpiryColumn10Deg: return 'Expiry Column Shelf Life 10°C';
      case EBoxLabelPriority.ExpiryColumn5Deg: return 'Expiry Column Shelf Life 5°C';
      case EBoxLabelPriority.ExpiryCalculation20Deg: return 'Expiry Calculation Shelf Life 20°C';
      case EBoxLabelPriority.ExpiryCalculation10Deg: return 'Expiry Calculation Shelf Life 10°C';
      case EBoxLabelPriority.ExpiryCalculation5Deg: return 'Expiry Calculation Shelf Life 5°C';
      case EBoxLabelPriority.ExpiryFromDispatch20Deg: return 'Expiry Calculation From Date of Dispatch 20°C';
      case EBoxLabelPriority.ExpiryFromDispatch10Deg: return 'Expiry Calculation From Date of Dispatch 10°C';
      case EBoxLabelPriority.ExpiryFromDispatch5Deg: return 'Expiry Calculation From Date of Dispatch 5°C';
    }
  }
  static getOptions() {
    // return Object.values(EBoxLabelPriority).filter(value => typeof value === 'number').map(m => ({ id: m, name: BoxLabelPriorityData.getText(+m) }));
    return [0,1,2,9,3,4,5,10,6,7,8,11].map(m => ({ id: m, name: BoxLabelPriorityData.getText(+m) }));
  }
}



export class ActionPointData {
  private static data = [
    // { id: 1, name: 'Sales --> General' },
    // { id: 2, name: 'Sales --> Commercial' },
    // { id: 3, name: 'Sales --> Technical' },
    // { id: 4, name: 'Factory --> R&D ongoing' },
    // { id: 5, name: 'Factory --> Commercial' },
    // { id: 6, name: 'Customer --> Technical' },
    // { id: 7, name: 'Customer --> Commercial' },
    // { id: 8, name: 'PO Admin' },
    { id: 1, name: 'Sales' },
    { id: 5, name: 'Factory' },
  ]
  static get(id: number | string) {
    return this.data.find(typeof id === 'number' ? f => f.id === id : f => f.name === id);
  }
  static getOptions() {
    return ActionPointData.data.map(m => ({ id: m.id, name: m.name }));
  }
}
export class OfferStatusData {
  private static data = [
    { id: 1, name: 'Initiation', color: '#bbddff',
      statusComments: [
        {id: 1, name: 'Technical Clarification'},
        {id: 2, name: 'Product in Test use'},
        {id: 3, name: 'Commercial Negotiation'},
      ],
    },
    { id: 2, name: 'Ordered', color: '#ffffaa',
      statusComments: [
        {id: 1, name: 'Best Delivery time'},
        {id: 2, name: 'Best Price Performance Ratio'},
        {id: 3, name: 'Best Technical Solution'},
        {id: 4, name: 'Cheapest Price'},
        {id: 5, name: 'Regular Business'},
        {id: 6, name: 'Single source'},
        {id: 6, name: 'Test order'},
      ],
    },
    { id: 3, name: 'Cancelled', color: '#ffddbb',
      statusComments: [
        {id: 1, name: 'By Customer - No invest'},
        {id: 2, name: 'By Customer - No suitable technical solution'},
        {id: 3, name: 'By Customer - Only Market research'},
        {id: 4, name: 'Cancelled by NR'},
      ],
    },
    { id: 4, name: 'Postponed', color: '#eeeeee',
      statusComments: [
      ],
    },
    { id: 5, name: 'Lost', color: '#ffbbbb',
      statusComments: [
        {id: 1, name: 'Delivery time'},
        {id: 2, name: 'No suitable product'},
        {id: 3, name: 'Price'},
        {id: 4, name: 'Response time'},
        {id: 5, name: 'Technical disadvantage'},
      ],
    },
    { id: 6, name: 'Completed', color: '#bbffbb',
      statusComments: [
        {id: 1, name: 'Best Delivery time'},
        {id: 2, name: 'Best Price Performance Ratio'},
        {id: 3, name: 'Best Technical Solution'},
        {id: 4, name: 'Cheapest Price'},
        {id: 5, name: 'Regular Business'},
        {id: 6, name: 'Single source'},
      ],
    },
    { id: 7, name: 'Revised', color: '#eeccff',
      statusComments: [
      ],
    },
    // { id: 7, name: 'Type of Business',
    //   statusComments: [
    //     {id: 1, name: 'Existing Business'},
    //     {id: 2, name: 'New Business'},
    //   ],
    // },
  ]
  static get(id: number | string) {
    return this.data.find(typeof id === 'number' ? f => f.id === id : f => f.name === id);
  }
  static getData() {
    return this.data;
  }
  static getStati() {
    return this.data.map(m => ({ id: m.id, name: m.name }));
  }
  static getName(id: number, forStatus = null) {
    if (forStatus === null) {
      return this.get(id) && this.get(id).name;
    } else {
      const el = OfferStatusData.get(forStatus)
      const item = el && el.statusComments.find(f => f.id === id);
      return (item && item.name) || '';
    }
  }
  static getOptions(forStatus: number = null) {
    // console.log('get options', forStatus, OfferStatusData.get(forStatus));
    if (forStatus === null || isNaN(forStatus)) {
      return OfferStatusData.data.map(m => ({ id: m.id, name: m.name }));
    } else {
      return OfferStatusData.get(forStatus).statusComments.map(m => ({ id: m.id, name: m.name }));
    }
  }

  static getTable() {
    let table = {};
    OfferStatusData.data.forEach(el => table[el.name] = ({ color: el.color, stati: el.statusComments.map(m => m.name) }));
    // console.log('offer', table);
    return table;
  }

}

export class InvoicePaymentData {
  private static data = [
    { id: 0, name: '', code: 'NP' },
    { id: 1, name: 'Down payment', code: 'DP' },
    { id: 2, name: 'Partial payment', code: 'PP' },
    { id: 3, name: 'Final payment', code: 'FP' },
  ]
  static get (id: number) {
    const ret =  InvoicePaymentData.data.find(s => s.id === +id)
    return ret;
  }
  static getOptions() {
    return InvoicePaymentData.data;
  }
}

export class FiscalYearData {
  static getOptions(): IDropdownOptions[] {
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 10;
    const dateRanges: { id: number, name: string }[] = [];

    for (let i = 0; i < 16; i++) {
        const year1 = startYear + i;
        const year2 = (startYear + i + 1) % 100;  // Get the last two digits of the next year
        const range = `${year1}/${year2 < 10 ? '0' + year2 : year2}`;  // Ensure the second year has two digits
        dateRanges.push({ id: startYear + i, name: range });
  
    }

    // console.log('date ranges', dateRanges);

    return dateRanges as unknown as IDropdownOptions[];  
  }
}

export class PaymentStatusData {
  private static data = [
    { id: 1, name: 'not paid' },
    { id: 2, name: 'partially paid' },
    { id: 3, name: 'fully paid' },
    { id: 4, name: 'not collectible' },
  ]
  static get (id: number) {
    return PaymentStatusData.data.find(s => s.id === id)
  }
  static getOptions() {
    return PaymentStatusData.data;
  }
}

export class PfStatusData {
  private static data = [
    { id: 1, name: 'N/A' },
    { id: 2, name: 'Cancelled' },
    { id: 3, name: 'Ordered' },
    { id: 4, name: 'En route' },
    { id: 5, name: 'Delivered' },
  ]
  static get (id: number) {
    return PfStatusData.data.find(s => s.id === id)
  }
  static getOptions() {
    return PfStatusData.data;
  }
}

export class IssuerData {
  private static data = [
    // { id: 1, name: 'NR Inc' },
    { id: 2, name: 'NRKA', link: 'nrka' },
    { id: 3, name: 'NRKG', link: 'nrkg' },
    { id: 4, name: 'NRKJ', link: 'nrka' },
    // { id: 5, name: 'NRKT' },
    // { id: 6, name: 'Tochigi Works' },
    { id: 7, name: 'NRVI', link: 'nrka' },
    { id: 8, name: 'Micamation', link: 'nrka' },
    { id: 9, name: 'AMS', link: 'nrka' },
    { id: 10, name: 'Fornax', link: 'nrka' },
  ]
  static get (idOrName: number | string) {
    return IssuerData.data.find(s => typeof idOrName === 'string'
      ? s.name.toLocaleLowerCase() === idOrName.toLocaleLowerCase() : s.id === idOrName
    );
  }
  static getOptions() {
    return IssuerData.data;
  }
}

export class BusinessUnitUtil {
  static readOnlyBus  = ['EM', 'VI', 'MM', 'BA'];
  static getBusinessUnitOptions(bus: BusinessUnitModel[], readOnly: boolean = false) {
    let filter = bus;
    if (readOnly) filter = bus.filter(f => BusinessUnitUtil.readOnlyBus.includes(f.code))
    return [{ id: null, name: 'all'}, ...filter.map(m => ({id: m._id, name: m.name}))];
  }
  static readOnlyFilter(bus: BusinessUnitModel[], bu: BusinessUnitModel) {
    const b = bus.find(f => bu && (f._id === bu._id || f._id === bu.toString()));
    return b && BusinessUnitUtil.readOnlyBus.includes(b.code);
  }
}

// legacy: now connected with business
export class ImporterData {
  private static data = [
    { id: 1, name: 'Bartech' },
    { id: 2, name: 'CEVA AT AIR' },
    { id: 3, name: 'CEVA AT SEA' },
    { id: 4, name: 'CEVA JP AIR DEL' },
    { id: 5, name: 'CEVA JP AIR INV' },
    { id: 6, name: 'DB SCHENKERsky' },
    { id: 7, name: 'Global Express' },
    { id: 8, name: 'Micamation AG' },
    { id: 9, name: 'Nippon Rika Vincent Industrie OLD' },
    { id: 10, name: 'NR Inc' },
    { id: 11, name: 'NRKA' },
    { id: 12, name: 'NRKG' },
    { id: 13, name: 'NRKJ' },
    { id: 14, name: 'NRKT' },
    { id: 15, name: 'NRVI NEW' },
    { id: 16, name: 'Tochigi Works' },
  ]
  static get (id: number) {
    return ImporterData.data.find(s => s.id === id)
  }
  static getOptions() {
    return ImporterData.data;
  }
}

export class CompilationTypeData {
  // parentCode obsolete => document-component -> getNewDownstreamDoctype()
  // TODO: remove parent code (not used)
  private static data = [
    { id: 17, code: 'TOF',  parentCode: [],          name: 'Template Offer',                   issuerContactType: EContactType.General, recipientContactType: EContactType.Legal },
    { id: 1, code: 'OF',    parentCode: [],          name: 'Offer',                            issuerContactType: EContactType.General, recipientContactType: EContactType.Legal },
    { id: 18, code: 'FCOR',  parentCode: ['OF'],          name: 'Frame Contract',                   issuerContactType: EContactType.General, recipientContactType: EContactType.Legal },
    { id: 2, code: 'OR',    parentCode: ['OF','FCOR'],      name: 'Order',                            issuerContactType: EContactType.General, recipientContactType: EContactType.Legal },
    { id: 3, code: 'OC',    parentCode: ['OR'],      name: 'Order Confirmation',               issuerContactType: EContactType.General, recipientContactType: EContactType.Legal },
    { id: 9, code: 'PL',    parentCode: ['OC'],      name: 'Packing List',                     issuerContactType: EContactType.General, recipientContactType: EContactType.Legal },
    { id: 4, code: 'SPO',   parentCode: ['OR','OC','OBIC'], name: 'Supplier Purchase Order',          issuerContactType: EContactType.General, recipientContactType: EContactType.Legal },
    { id: 12,code: 'SPC',   parentCode: ['OR','OC'], name: 'Chain Transaction SPO',            issuerContactType: EContactType.General, recipientContactType: EContactType.Legal },
    { id: 5, code: 'IN',    parentCode: ['OC','DN','PL'], name: 'Invoice',                          issuerContactType: EContactType.General, recipientContactType: EContactType.Invoice },
    { id: 10,code: 'DN',    parentCode: ['OC','PL'], name: 'Delivery Note',                    issuerContactType: EContactType.General, recipientContactType: EContactType.Legal },
    { id: 19, code: 'OBIC', parentCode: ['FCOR'],      name: 'OBIC (PF)SPO', issuerContactType: EContactType.General, recipientContactType: EContactType.Legal },
    { id: 6, code: 'PFSPO', parentCode: ['FCOR', 'OR','OC','OBIC'],      name: 'Proforma Supplier Purchase Order', issuerContactType: EContactType.General, recipientContactType: EContactType.Legal },
    { id: 13,code: 'PFSPC', parentCode: ['OR','OC'],      name: 'Proforma Chain Transaction SPO',   issuerContactType: EContactType.General, recipientContactType: EContactType.Legal },
    { id: 7, code: 'PFIN',  parentCode: ['OC','DN','PL'],      name: 'Proforma Invoice',                 
      headings: ['Proforma Invoice', 'Customs Invoice', 'Shipping Invoice'],                   issuerContactType: EContactType.General, recipientContactType: EContactType.Invoice },
    { id: 8, code: 'CN',    parentCode: ['IN'],      name: 'Credit Note',
      headings: ['Credit Note', 'Cancellation Invoice', 'Correction Invoice'],                 issuerContactType: EContactType.General, recipientContactType: EContactType.Legal },
    { id: 11,code: 'PFCN',  parentCode: ['PFIN'],    name: 'Proforma Credit Note',             issuerContactType: EContactType.General, recipientContactType: EContactType.Legal },
    { id: 14,code: 'OR-NRKA',parentCode: ['OR'],     name: 'NRKA Order' /* NRKG-PFSPC => NRKA-OR*/, issuerContactType: EContactType.General, recipientContactType: EContactType.Legal, hidden: true },
    { id: 15,code: 'OC-NRKG',parentCode: ['OR'],     name: 'NRKG Order Confirmation',          issuerContactType: EContactType.General, recipientContactType: EContactType.Legal, hidden: true },
    { id: 16,code: 'CS',    parentCode: [],          name: 'Combined Shipment',                issuerContactType: EContactType.General, recipientContactType: EContactType.Legal },
  ]
  static get = (codeOrId: any) => CompilationTypeData.data.find(s => (typeof codeOrId === 'number') ? codeOrId === s.id : codeOrId === s.code)
  static getOptions() {
    return CompilationTypeData.data.filter(f => !f.hidden).map(i => ({ id: i.id, name: i.name }));
  }
  static getTypes = () => CompilationTypeData.data.filter(f => !f.hidden);
  static getFromDocumentNumber = (code) => CompilationTypeData.data.find(c => c.code === code.replace(/NRK.*/, ''));
  static getHeading = (doc: CompilationModel) => {
    const data = CompilationTypeData.get(doc.type);
    if (doc.type === 2 && doc.orderAcknoledgement && data) return 'Frame Contract Acknowledgement';
    if (data && data.headings && doc.headingType) return data.headings[doc.headingType - 1];
    return data && data.name;
  }
}

// export const pfspoInDisclaimer = 
// `Invoices adress: invoices_NRKA@nipponrika.jp
// Subject must contain SPO#, internal reference, IN#
// Max 1 pdf per email`;

// legacy: now edited inline
export class MeansOfTransportationData {
  private static data = [
    { id: 0, name: '' },
    { id: 1, name: 'STANDARD' },
    { id: 2, name: 'AIR' },
    { id: 3, name: 'UPS' },
    { id: 4, name: 'SEA' },
    { id: 5, name: 'TNT' },
    { id: 6, name: 'COURIER SERVICE' },
    { id: 7, name: 'PICK UP FRIED-SPED INTERNAT.' },
    { id: 8, name: 'CHRONOPOST' },
    { id: 9, name: 'TRUCK VIA EURASIA GLOBAL LOGISTICS' },
    { id: 10, name: 'DB SCHENKER Land Transport' },
    { id: 11, name: 'BBL Transport' },
    { id: 12, name: 'CEVALOGISTICS' },
    { id: 13, name: 'DHL' },
    { id: 14, name: 'HANDOVER' },
    { id: 15, name: 'EURASIA GLOBAL LOGISTIC' },
    { id: 16, name: 'DHL' },
    { id: 17, name: 'Hadolt Global Express' },
  ]
  static get (id: number) {
    return MeansOfTransportationData.data.find(s => s.id === id)
  }
  static getOptions() {
    return MeansOfTransportationData.data;
  }
}

// legacy: now in utilities / tax hint editor
export class TaxHintData {
  private static data = [
    { id: 1, name: 'Not taxable in Austria. Tax Free intracommunity dispatch following sec 6 VAT Act' },
    { id: 2, name: 'Innergemeinschaftliche Lieferung, steuerfrei gemäss § 6a Abs. 1 UStG.' },
    { id: 3, name: 'Steuerfreie Ausfuhrlieferung' },
    { id: 5, name: 'Tax Free Export Delivery' },
    { id: 6, name: 'Intracommunity triangular transaction, VAT liability rests with service recipient' },
    { id: 7, name: 'Reverse Charge System: Die Umsatzsteuerschuld geht auf dem Leistungsempfänger über' },
    { id: 8, name: 'Reverse Charge System: Not taxable in Austria. According to Article 194, 196 of Council Directive 2006/112/EEC, VAT Liability rests with the service recipient' },
    { id: 10, name: 'Reverse Charge System: Not taxable in Austria. According to Article 194, 196 of Council Directive 2006/112/EEC, VAT Liability rests with the service recipient' },
    { id: 11, name: 'Export supply - exempt from VAT according to Art. 146(1) of Council Directive 2006/112/EC' }    ]
  static get (id: number) {
    return TaxHintData.data.find(s => s.id === id)
  }
  static getOptions() {
    return TaxHintData.data;
  }
}

// legacy: now in utilities / packing details
export class PalletType {
  private static data = [
    { id: 'P0', name: 'non palletised shipment' },
    // { id: 'P9', name: 'ship with other DN' },
    { id: 'P1', name: '80x60' },
    { id: 'P8', name: '100x100' },
    { id: 'P2', name: '113x113' },
    { id: 'P6', name: '115x115' },
    { id: 'P3', name: '119x113' },
    { id: 'P4', name: '120x80' },
    { id: 'P5', name: '120x113' },
    { id: 'P7', name: '123x123' },
  ];
  static getOptions() {
    return PalletType.data.map(m => ({ id: m.id, name: `${m.id} (${m.name})`}));
  }
  static getName(id: string) {
    const val = PalletType.getOptions().find(f => f.id === id);
    return val && val.name;
  }
}
export type PalettType = PalletType;

// legacy: now in utilities / packing details
export class TypeOfCartonBox {
  private static data = [
    { id: 'CB7', name: '25x25x20' },
    { id: 'CB8', name: '36x36x30' },
    { id: 'CB1', name: '46x24x36' },
    { id: 'CB2', name: '46x24x37' },
    { id: 'CB9', name: '49x31x22' },
    { id: 'CB3', name: '57x39x48' },
    { id: 'CB4', name: '58x39x49' },
    { id: 'CB5', name: '103x54x6' },
    { id: 'CB6', name: '113x25x25' },
  ];
  static getOptions() {
    return [{id: null, name: ''}, ...TypeOfCartonBox.data.map(m => ({ id: m.id, name: `${m.id} (${m.name})`}))];
  }
  static getName(id: string) {
    const val = TypeOfCartonBox.getOptions().find(f => f.id === id);
    return val && val.name;
  }
}

export class Incoterms {
  private static data = [
    { id: 'EXW', name: 'EX Works' },
    { id: 'FCA', name: 'Free Carrier' },
    { id: 'FAS', name: 'Free Alongside Ship' },
    { id: 'FOB', name: 'Free On Board' },
    { id: 'CFR', name: 'Cost And FReight' },
    { id: 'CIF', name: 'Cost Insurance Freight' },
    { id: 'DAP', name: 'Delivered At Place' },
    { id: 'DPU', name: 'Delivered at Place Unloaded' },
    { id: 'CPT', name: 'Carriage Paid To' },
    { id: 'CIP', name: 'Carriage Insurance Paid' },
    { id: 'DDP', name: 'Delivery Duty Paid' },
    { id: 'DAT', name: 'Delivery at Terminal' },
  ]
  static getOptions() {
    return [{ id: null, name: '' }, ...Incoterms.data.map(m => ({ id: m.id, name: `${m.id} (${m.name})`}))];
  }
}

export class TransportStatusData {
  private static data = [
    { id: 1, code: 'planned', name: 'Planned' },
    { id: 2, code: 'enRoute', name: 'En route' }, // En route with non-breaking space !
    { id: 3, code: 'completed', name: 'Completed' },
  ]
  static get = (codeOrId: any) => TransportStatusData.data.find(s => (typeof codeOrId === 'number') ? codeOrId === s.id : codeOrId === s.code)
  static getData = () => TransportStatusData.data;
}

export class SpoStatusData {
  private static data = [
    { id: 0, code: 'unconfirmed', name: 'Unconfirmed' },
    { id: 1, code: 'confirmed', name: 'Confirmed' },
    { id: 2, code: 'completed', name: 'Completed' },
  ]
  static get = (codeOrId: any) => SpoStatusData.data.find(s => (typeof codeOrId === 'number') ? codeOrId === s.id : codeOrId === s.code)
  static getData = () => SpoStatusData.data;
}

export class AvailabilityStatusData {
  private static data = [
    { id: 1, code: 'notCompleted', name: 'Not completed' },
    { id: 2, code: 'completed', name: 'Completed' },
  ]
  static get = (codeOrId: any) => AvailabilityStatusData.data.find(s => (typeof codeOrId === 'number') ? codeOrId === s.id : codeOrId === s.code)
  static getData = () => AvailabilityStatusData.data;
}

export class DateSelectionData {
  private static data = [];
  static readonly thisCalendarWeek      = new DateSelectionData(2, 'This calendar week');
  static readonly lastCalendarWeek      = new DateSelectionData(3, 'Last calendar week');
  static readonly nextCalendarWeek      = new DateSelectionData(18, 'Next calendar week');
  static readonly currentMonth          = new DateSelectionData(4, 'Current month');
  static readonly lastMonth             = new DateSelectionData(5, 'Last month');
  static readonly currentYear           = new DateSelectionData(6, 'Current year (' + (new Date().getFullYear()) + ')');
  static readonly currentFiscalYear     = new DateSelectionData(19, 'Current fiscal year');
  static readonly yearToDate            = new DateSelectionData(15, 'Year to date');
  static readonly lastYear365           = new DateSelectionData(7, 'Last year (past 365 days)');
  static readonly lastCalendarYear      = new DateSelectionData(8, 'Last calendar year (' + (new Date().getFullYear()-1) + ')');
  static readonly lastFiscalYear        = new DateSelectionData(20, 'Last fiscal year');
  static readonly nextYear365           = new DateSelectionData(9, 'Next year (next 365 days)');
  static readonly nextFiscalYear        = new DateSelectionData(21, 'Next fiscal year');
  static readonly past365TillNextMonth  = new DateSelectionData(10, 'Past 365 days till next month');
  static readonly past365ToNext365      = new DateSelectionData(11, 'Past 365 days and next 365 days');
  static readonly past30ToNext365       = new DateSelectionData(17, 'Past 30 days and next 365 days');
  static readonly allTillToday          = new DateSelectionData(12, 'All till today');
  static readonly allTillNextWeek       = new DateSelectionData(14, 'All till end of next week');
  static readonly allPlus30             = new DateSelectionData(16, 'All till today plus 30 days')
  static readonly all                   = new DateSelectionData(13, 'All');

  private constructor(public readonly id: number, public readonly name: string) {
    DateSelectionData.data.push({ id: id, name: name });
  }
  static getOptions = () => DateSelectionData.data;
}

// TODO: obsolete
// legacy: now integrated in business / utils / Business ToP Editor
export class DeliveryConditionsData {
  private static data = [
    { id: 9, name: 'EXW' },
    { id: 10, name: 'FCA' },
    { id: 11, name: 'CPT' },
    { id: 12, name: 'CIP' },
    { id: 13, name: 'DAT' },
    { id: 14, name: 'DAP Zehle – Bekaa, LEBANON' },
    { id: 15, name: 'DDP' },
    { id: 16, name: 'FAS' },
    { id: 17, name: 'FOB' },
    { id: 18, name: 'CFR' },
    { id: 19, name: 'CIF' },
    { id: 20, name: 'DAF' },
    { id: 21, name: 'DES' },
    { id: 22, name: 'DEQ' },
    { id: 23, name: 'DDU' },
    { id: 24, name: 'DAP 75301 Jüri Rae Vald, ESTONIA' },
    { id: 25, name: 'CPT Tehran, IRAN' },
    { id: 26, name: 'EXW Vienna, AUSTRIA' },
    { id: 27, name: 'DAP 2351 Wr. Neudorf, AUSTRIA' },
    { id: 28, name: 'DAP 8160 Weiz, AUSTRIA' },
    { id: 29, name: 'DAP 12822 13211 Riyadh, KSA' },
    { id: 30, name: 'DAP 20200 Beasain Gipuzkoa, SPAIN' },
    { id: 31, name: 'DDP 721 36 Västeras, SWEDEN' },
    { id: 32, name: 'DAP Madrid, SPAIN' },
    { id: 33, name: 'DAP 16152 Genova (GE), ITALY' },
    { id: 34, name: 'CIP Chennai Airport, INDIA' },
    { id: 35, name: 'DAP 25290 Ornans, FRANCE' },
    { id: 36, name: 'DAP 08940 Cornellà de Llobregat, SPAIN' },
    { id: 37, name: 'CIF Yokohama Port, JAPAN' },
    { id: 38, name: 'DAP 23568 Lübeck, GERMANY' },
    { id: 39, name: 'DAP 75301 Jüri Harjumaa, ESTONIA' },
    { id: 40, name: 'DAP 72379 Hechingen, GERMANY' },
    { id: 41, name: 'DAT Erenkoy Customs Istanbul TURKEY (Incoterms 2010 ICC)' },
    { id: 42, name: 'DAP 34074 Monfalcone (GO), ITALY' },
    { id: 43, name: 'DAP 04720 Döbeln, GERMANY' },
    { id: 44, name: 'DAP 90441 Nuremberg, GERMANY' },
    { id: 45, name: 'FCA 88444 Ummendorf, GERMANY' },
    { id: 46, name: 'DAP 88400 Biberach a.d. Riß, GERMANY' },
    { id: 47, name: 'DAP 32010 Alpago (BL), ITALY' },
    { id: 48, name: 'DAP Istanbul, TURKEY' },
    { id: 49, name: 'DAP 301 28 Plzen, CZECH REPUBLIC' },
    { id: 50, name: 'DDP 8160 Weiz, AUSTRIA' },
    { id: 51, name: 'DDP 75301 Jüri Harjuma, ESTONIA' },
    { id: 52, name: 'EXW Nippon Rika Vincent Industrie, FRANCE' },
    { id: 53, name: 'DAP 601-1433 Kyoto, JAPAN' },
    { id: 54, name: 'DAP CV23 0WB Rugby, UK' },
    { id: 55, name: 'DAP 32602 Vlotho, GERMANY' },
    { id: 56, name: 'DAP Ortuella (Bizkaia), SPAIN' },
    { id: 57, name: 'DAP 400641 Cluj-Napoca, ROMANIA' },
    { id: 58, name: 'DAP NR1 1JN Norfolk, UK' },
    { id: 59, name: 'EXW 321-0214 Tochigi, JAPAN' },
    { id: 60, name: 'DDP 04720 Mochau, GERMANY' },
    { id: 61, name: 'DAP 410603 Oradea, ROMANIA' },
    { id: 62, name: 'DAP 95526 Cergy Pontoise Cedex, FRANCE' },
    { id: 63, name: 'CIF Dammam Airport, KSA' },
    { id: 64, name: 'DAT Warehouse TR-34 Istanbul, TURKEY' },
    { id: 65, name: 'DAP 69350 La Mulatière, FRANCE' },
    { id: 66, name: 'DAP 84099 San Cipriano Picentino (SA), ITALY' },
    { id: 67, name: 'DAP Nuremberg, GERMANY' },
    { id: 68, name: 'FCA CEVA Vienna Airport, AUSTRIA' },
    { id: 69, name: 'DAP 140-8503 Shinagawa-ku, JAPAN' },
    { id: 70, name: 'DAP 321-0214 Tochigi, JAPAN' },
    { id: 71, name: 'DAP Penn Coil Glassport, USA' },
    { id: 72, name: 'DAT ISTANBUL CUSTOMS TERMINAL-ERENKOY, TURKEY BY TRUCK' },
    { id: 73, name: 'DAP 80147 Napoli (NA), ITALY' },
    { id: 74, name: 'DAP 00380 Helsinki, FINLAND' },
    { id: 75, name: 'DDP 00380 Helsinki, FINLAND' },
    { id: 77, name: 'DAP 465 95 Nossebro, SWEDEN' },
    { id: 78, name: 'DAP Rubí, SPAIN' },
    { id: 79, name: 'DAP Córdoba, SPAIN' },
    { id: 80, name: 'DAP 36071 Arzignano (VI), ITALY' },
    { id: 81, name: 'DAP 041919 Bucharest, RUMANIA' },
    { id: 82, name: 'DAP 06920 Mihallıççık Ankara, TURKEY' },
    { id: 83, name: 'DAP 31961 Jubail Industrial City, KSA' },
    { id: 84, name: 'DDP Nippon Rika Vincent Industrie, FRANCE' },
    { id: 85, name: 'DAP 45800 Saint Jean de Braye, FRANCE' },
    { id: 86, name: 'DDP 69350 La Mulatière, FRANCE' },
    { id: 87, name: 'DAP Riga LV-1005, LATVIA' },
    { id: 88, name: 'DAP Pell City AL 351125, USA' },
    { id: 89, name: 'DAP 90018 Belfort Cedex, FRANCE' },
    { id: 90, name: 'DAP 99-320 Żychlin, POLAND' },
    { id: 91, name: 'CIP Dammam Airport, KSA' },
    { id: 92, name: 'DAP 200746 Craiova, ROMANIA' },
    { id: 93, name: 'DAP 618911 Lysva, RUSSIA' },
    { id: 94, name: 'DAP 193315 St. Petersburg, RUSSIA' },
    { id: 95, name: 'DAP 196105 St. Petersburg, RUSSIA' },
    { id: 96, name: 'DAP 42-701 Lubliniec, POLAND' },
    { id: 97, name: 'DAP 81025 Marcianise (CE), ITALY' },
    { id: 98, name: 'DAP 01150 Vaux en Bugey, FRANCE' },
    { id: 99, name: 'DDP 20200 Beasain Gipuzkoa, SPAIN' },
    { id: 100, name: 'DAP 301 00 Plzen, CZECH REPUBLIC' },
    { id: 101, name: '102	DAP 24000 Subotica, SERBIA' },
    { id: 103, name: 'DAP 3167643586 Tehran, IRAN' },
    { id: 104, name: 'DAP 35023 Bagnoli Di Sopra (PD), ITALY' },
    { id: 105, name: 'DAP 200440 Craiova, ROMANIA' },
    { id: 106, name: 'DAP 68200 Mulhouse, FRANCE' },
    { id: 107, name: 'DAP 111024 Moscow, RUSSIA' },
    { id: 108, name: 'DAP 1650 Sellebakk, NORWAY' },
    { id: 109, name: 'DAP H4S 1R9 Montreal, CANADA' },
    { id: 110, name: 'DAP 1630 Gamle Fredriksstad, NORWAY' },
    { id: 111, name: 'DDP 3167643586 Tehran, IRAN' },
    { id: 112, name: 'DAP Sesto San Giovanni (MI), ITALY' },
    { id: 113, name: 'DAP B12 0JJ Birmingham, UK' },
    { id: 114, name: 'DAP NE6 4LT Newcastle Upon Tyne, UK' },
    { id: 115, name: 'DAP 10553 Berlin, GERMANY' },
    { id: 116, name: 'DAP 28207 Bremen, GERMANY' },
    { id: 117, name: 'DAP 59572 Jeumont, FRANCE' },
    { id: 118, name: 'DAP 27283 Verden, GERMANY' },
    { id: 119, name: 'DDP 27283 Verden, GERMANY' },
    { id: 120, name: 'DAP 73037 Göppingen, GERMANY' },
    { id: 121, name: 'DDP 45478 Mülheim an der Ruhr, GERMANY' },
    { id: 122, name: 'DAP 46397 Bocholt, GERMANY' },
    { id: 123, name: 'DAP 41238 Mönchengadbach, GERMANY' },
    { id: 124, name: 'DAP 06849 Dessau Roßlau, GERMANY' },
    { id: 125, name: 'DAP 79261 Gutach, GERMANY' },
    { id: 126, name: 'DAP 30519 Hannover, GERMANY' },
    { id: 127, name: 'DAP 01257 Dresden, GERMANY' },
    { id: 128, name: 'DAP 47441 Moers, GERMANY' },
    { id: 129, name: 'DAP 92100 Boulogne Billancourt, FRANCE' },
    { id: 130, name: 'DAP 36460 Merkers, GERMANY' },
    { id: 131, name: 'DAP 28199 Bremen, GERMANY' },
    { id: 132, name: 'DAP 720000 Ostrava, CZECH REPUBLIC' },
    { id: 133, name: 'DAP 06842 Dessau, GERMANY' },
    { id: 134, name: 'DAP 5401 Baden, SWITZERLAND' },
    { id: 135, name: '136	DDP 32010 Alpago (BL), ITALY' },
    { id: 137, name: 'DAP 6030 Marchienne-au-Pont, BELGIUM' },
    { id: 138, name: 'DAP ZI Sud Ouest Mohammedia, MOROCCO' },
    { id: 139, name: 'DAP Ain-Sebaâ, Casablanca, MOROCCO' },
    { id: 140, name: 'CFR Casablanca, MOROCCO' },
    { id: 141, name: 'DAP 8108 Dällikon-Zürich, SWITZERLAND' },
    { id: 142, name: 'EXW 8108 Dällikon-Zürich, SWITZERLAND' },
    { id: 143, name: 'CIF Fredrickstad Seaport, NORWAY' },
    { id: 144, name: 'DAP Wroclaw, POLAND' },
    { id: 145, name: 'FCA Vienna, AUSTRIA' },
    { id: 146, name: 'FCA Schenker Vienna, AUSTRIA' },
    { id: 147, name: 'DAP Moscow, RUSSIA' },
    { id: 148, name: 'EXW 04720 Döbeln, GERMANY' },
    { id: 149, name: 'DAP 20400 Ibarra, SPAIN' },
    { id: 150, name: 'CIF Thessaloniki, GREECE' },
    { id: 151, name: 'CIP Istanbul, TURKEY' },
    { id: 152, name: 'DAP Rotherham S26 5NU, UK' },
    { id: 153, name: 'DDP 42-701 Lubliniec, POLAND' },
    { id: 154, name: 'DAP 20590 Casablanca, MOROCCO' },
    { id: 155, name: 'DAP H4S 1R9 Montréal, Quebec, CANADA' },
    { id: 156, name: 'DAP 562 24 Thessaloniki, GREEECE' },
    { id: 157, name: 'DAP 8005 Zürich, SWITZERLAND' },
    { id: 158, name: 'DAP 54533 Laufeld (Rheinland-Pfalz), GERMANY' },
    { id: 159, name: 'EXW Tokyo, JAPAN' },
    { id: 160, name: 'DAP 04741 Rosswein OT Ossig, GERMANY' },
    { id: 161, name: 'DAP Mersin, TURKEY' },
    { id: 162, name: '163	DAP Tochigi 321-0214, JAPAN' },
    { id: 164, name: 'DAP 45478 Mühlheim an der Ruhr, GERMANY' },
    { id: 165, name: 'CIP St. Petersburg Airport, RUSSIA' },
    { id: 166, name: 'DAP 16727 Velten, GERMANY' },
    { id: 167, name: 'DAP 185 37 Piraeus, GREECE' },
    { id: 168, name: 'DAP 222 48170 Edificio, Zamudio, Vizcaya SPAIN' },
    { id: 169, name: 'DAP 2351 Wiener Neudorf AUSTRIA' },
    { id: 170, name: 'DAP 14045 Incisa Scapaccino (AT), ITALY' },
    { id: 171, name: 'DAP 47138 Duisburg, GERMANY' },
    { id: 172, name: 'DAP 29022 Bobbio (PC), ITALY' },
    { id: 173, name: 'DAP Bilbao, SPAIN' },
    { id: 174, name: 'DAT Kocaeli Customs Warehouse, TURKEY' },
    { id: 175, name: 'CIF Istanbul, TURKEY' },
    { id: 176, name: 'DAP 48510 Trapagarán Bizkaia, SPAIN' },
    { id: 177, name: 'DDP 47906 Kempen, GERMANY' },
    { id: 178, name: 'DDP Vienna, AUSTRIA' },
    { id: 179, name: 'DAP Norwich, Norfolk, UK' },
    { id: 180, name: 'DAP Balikesir, TURKEY' },
    { id: 181, name: 'DAP Nippon Rika Vincent Industrie, FRANCE' },
    { id: 182, name: 'DAP Damel, POLAND' },
    { id: 183, name: 'CIP Jebel Ali, UAE' },
    { id: 184, name: 'DAP Preding/Weiz, AUSTRIA' },
    { id: 185, name: 'DDP Flas Emaye, Istanbul, TURKEY' },
    { id: 186, name: 'DAP DC Wort, Benoni, SOUTH AFRICA' },
    { id: 187, name: 'DAP 54250 Champigneulles, FRANCE' },
    { id: 188, name: 'DDP Vaux-en-Bugey, FRANCE' },
    { id: 189, name: 'CIP Balıkesir, TURKEY' },
    { id: 190, name: 'DAP 4470-908 Maia, PORTUGAL' },
  ]
  static get (id: number) {
    return DeliveryConditionsData.data.find(s => s.id === id)
  }
  static getOptions() {
    return DeliveryConditionsData.data;
  }
}

// TODO: obsolete
// legacy: mow integrated in business / utils / Business ToP Editor
export class PaymentConditionsData {
  private static data = [
    { id: 7, name: 'WITHIN 30 DAYS VIA BANK TRANSFER' },
    { id: 8, name: 'WITHIN 45 DAYS VIA BANK TRANSFER' },
    { id: 9, name: 'WITHIN 60 DAYS VIA BANK TRANSFER' },
    { id: 10, name: 'WITHIN 75 DAYS VIA BANK TRANSFER' },
    { id: 11, name: 'WITHIN 90 DAYS VIA BANK TRANSFER' },
    { id: 12, name: 'NET 30 EOM VIA BANK TRANSFER' },
    { id: 13, name: 'NET 45 EOM VIA BANK TRANSFER' },
    { id: 14, name: 'NET 90 EOM VIA BANK TRANSFER' },
    { id: 15, name: 'NET 45 EOM 05 VIA BANK TRANSFER' },
    { id: 16, name: 'NET 45 EOM 10 VIA BANK TRANSFER' },
    { id: 17, name: 'NET 60 EOM 10 VIA BANK TRANSFER' },
    { id: 18, name: 'NET 60 EOM 25 VIA BANK TRANSFER' },
    { id: 19, name: '90% OF TOTAL AGAINST SHIPPING VIA BANK TRANSFER' },
    { id: 20, name: 'WITHIN 48H OF RECEIPT VIA BANK TRANSFER' },
    { id: 21, name: 'CUSTOMS PURPOSE ONLY' },
    { id: 22, name: 'NO COMMERCIAL VALUE / CUSTOMS PURPOSE ONLY' },
    { id: 23, name: '10% after final acceptance of commissioning test within 45 days' },
    { id: 24, name: '100% payable at sight against the shipping document per L/C' },
    { id: 25, name: 'DUE AT SIGHT VIA BANK TRANSFER' },
    { id: 26, name: 'DUE UPON RECEIPT VIA BANK TRANSFER' },
    { id: 27, name: 'PAYMENT PRIOR TO DISPATCH VIA BANK TRANSFER' },
    { id: 28, name: 'WITHIN 14 DAYS VIA BANK TRANSFER' },
    { id: 29, name: 'PER L/C' },
    { id: 30, name: 'SEE BELOW*' },
    { id: 31, name: 'NET 60 EOM VIA BANK TRANSFER' },
    { id: 32, name: 'SEE BELOW**' },
    { id: 33, name: 'SEE BELOW***' },
    { id: 34, name: 'NET 30 EOM 05 VIA BANK TRANSFER' },
    { id: 35, name: 'ADVANCE PAYMENT 50% OF TOTAL AT RECEIPT OF ORDER CONFIRMATION, 50% OF TOTAL VIA BANK TRANSFER WITHIN 30 DAYS AFTER DELIVERY' },
    { id: 36, name: 'WITHIN 120 DAYS VIA BANK TRANSFER' },
    { id: 37, name: 'PAYMENT IN ADVANCE VIA BANK TRANSFER' },
    { id: 39, name: '30% - down payment by bank transfer at receipt of invoice, 70% - payment at EXW Brignais before machine shipment' },
    { id: 40, name: 'WITHIN 30 DAYS FROM SHIPMENT DATE VIA BANK TRANSFER' },
    { id: 41, name: 'WITHIN 3 MONTHS FROM SHIPMENT DATE VIA BANK TRANSFER' },
    { id: 42, name: 'WITHIN 6 MONTHS FROM SHIPMENT DATE VIA BANK TRANSFER' },
    { id: 43, name: 'WITHIN 9 MONTHS FROM SHIPMENT DATE VIA BANK TRANSFER' },
    { id: 44, name: 'DDP 47906 Kempen, GERMANY' },
  ]
  static get (id: number) {
    return PaymentConditionsData.data.find(s => s.id === id)
  }
  static getOptions() {
    return PaymentConditionsData.data;
  }
}

export class DocConfig {
  private static sections = {
    defaultAddress: {
      index: -1,
      fields: [
        { title: true },
        { field: EContactFieldType.Address2 },
        { field: EContactFieldType.Street },
        { field: EContactFieldType.Zip, nobreak: true },
        { field: EContactFieldType.City },
        { field: EContactFieldType.Country },
        { field: EContactFieldType.Info },
        // { field: EContactFieldType.Instructions, types: [EContactType.InvoiceSendTo, EContactType.DNSendTo, EContactType.TCSendTo] },
        { field: EContactFieldType.VAT_Number, label: 'VAT ID: ', types: [EContactType.Invoice] },
        { field: EContactFieldType.Email, types: [EContactType.Invoice, EContactType.InvoiceSendTo] },
      ],
    },
    recipientAddress: {
      index: -1,
      fields: [
        { title: true },
        { field: EContactFieldType.Address2 },
        { field: EContactFieldType.Street },
        { field: EContactFieldType.Zip, nobreak: true },
        { field: EContactFieldType.City },
        { field: EContactFieldType.Country },
        { field: EContactFieldType.Info },
        // { field: EContactFieldType.Instructions, types: [EContactType.InvoiceSendTo, EContactType.DNSendTo, EContactType.TCSendTo] },
        { field: EContactFieldType.VAT_Number, label: 'VAT ID: ', types: [EContactType.Invoice] },
        { field: EContactFieldType.Email, types: [EContactType.Invoice, EContactType.InvoiceSendTo] },
      ],
      mandatory: ['IN'],
    },
    invoiceSendToAddress: {
      index: -1,
      fields: [
        { title: false },
        { field: EContactFieldType.Street },
        { field: EContactFieldType.Zip, nobreak: true },
        { field: EContactFieldType.City },
        { field: EContactFieldType.Country },
        { field: EContactFieldType.Info },
        // { field: EContactFieldType.Instructions, types: [EContactType.InvoiceSendTo, EContactType.DNSendTo, EContactType.TCSendTo] },
        { field: EContactFieldType.VAT_Number, label: 'VAT ID: ', types: [EContactType.Invoice] },
        { field: EContactFieldType.Email, types: [EContactType.Invoice, EContactType.InvoiceSendTo] },
      ],
    },
    sendToInfo: {
      index: -1,
      fields: [
        { title: true },
        { field: EContactFieldType.Street },
        { field: EContactFieldType.Zip, nobreak: true },
        { field: EContactFieldType.City },
        { field: EContactFieldType.Country },
        { field: EContactFieldType.Info },
        { field: EContactFieldType.Email},
        { field: EContactFieldType.SendingDate, nobreak: true},
        { field: EContactFieldType.SendingDays},
        { field: EContactFieldType.Instructions},
      ],
      mandatory: ['IN'],
    },
    recipientUID: {
      index: -1,
      fields: [
        { title: false },
        { field: EContactFieldType.VAT_Number, label: 'VAT ID: ' },
        { field: EContactFieldType.RegistrationNumber, label: 'CRN: ' },
      ],
    },
    issuerAddress: {
      index: -1,
      fields: [
        { title: true },
        { field: EContactFieldType.Street },
        { field: EContactFieldType.Zip, nobreak: true },
        { field: EContactFieldType.City },
        { field: EContactFieldType.Country },
        // { field: EContactFieldType.VAT_Number, label: 'VAT ID: ' },
        // { field: EContactFieldType.RegistrationNumber },
      ],
    },
    recipientContact: {
      index: -1,
      fields: [
        { title: true },
        { field: EContactFieldType.Mobile },
        { field: EContactFieldType.Phone },
        { field: EContactFieldType.BusinessLandline },
        { field: EContactFieldType.Email },
      ],
    },
    issuerContact: {
      index: -1,
      fields: [
        { title: true },
        { field: EContactFieldType.Mobile },
        { field: EContactFieldType.Phone },
        { field: EContactFieldType.BusinessLandline },
        { field: EContactFieldType.Email },
      ],
    },
    trackSpoPayment: {
      index: -1,
      docs: ['SPO', 'PFSPO'],
      label: 'Track SPO Payment',
    },
    deliveryDate: {
      index: -1,
      docs: ['OF', 'FCOR', 'OR', 'OC', 'SPO', 'IN', 'DN', 'PL', 'PFSPO', 'PFIN', 'CN', 'PFCN', 'SPC', 'PFSPC'],
    },
    dueDate: {
      index: -1,
      docs: ['IN', 'PFIN'],
    },
    // dueText: {      // linked to dueDate
    //   index: -1,
    //   docs: ['IN', 'PFIN'],
    // },
    invoiceStatus: {
      index: -1,
      label: 'Invoice Status',
      docs: ['IN', 'PFIN', 'CN'],
    },
    invoicePayment: {
      index: -1,
      docs: ['IN', 'PFIN', 'CN'],
    },
    fiscalYear: {
      index: -1,
      docs: ['OR', 'OC', 'IN', 'DN', 'CN'],
    },
    differentInvoiceAddress: {
      index: -1,
      label: 'Different Invoice Address',
      docs: ['FCOR', 'OR', 'OC', 'PL', 'DN'],
    },
    invoiceSendTo: {
      index: -1,
      docs: ['OF', 'OR', 'OC', 'PL', 'DN', 'IN', 'PFIN'],
    },
    discount: {
      index: -1,
      docs: ['OF', 'FCOR', 'OR', 'OC', 'IN', 'PFIN'],
    },
    notInInvoiceReport: {
      index: -1,
      docs: ['IN', 'PFIN', 'CN', 'PFCN'],
      sync: ['OC', 'PL', 'DN']
    },
    notInOrderReport: {
      index: -1,
      docs: ['FCOR', 'OR'],
    },
    creditNoteReason: {
      index: -1,
      docs: ['CN'],
    },
    prices: {
      index: -1,
      docs:  ['OF', 'FCOR', 'OR', 'OC', 'SPO', 'IN', 'OBIC', 'PFSPO', 'PFIN', 'CN', 'PFCN', 'SPC', 'PFSPC'],
      price: ['SP', 'SP',   'SP', 'SP', 'PP',  'SP', 'PP',   'PP',    'PP',   'SP', 'PP',   'SP',  'PP'],
    },
    grossWeight: {
      index: -1,
      docs: ['PL', 'DN'],
    },
    vat: {
      index: -1,
      docs: ['OF', 'FCOR', 'OR', 'OC', 'SPO', 'IN', 'OBIC', 'PFSPO', 'PFIN', 'CN', 'PFCN', 'SPC', 'PFSPC'],
    },
    taxHint: {
      index: 5, // 0,
      label: 'VAT Exemption Reference',
      // label: 'Transaction exempt from VAT taxation due to:',
      type: 'custom',
      options: TaxHintData.getOptions(),
      docs: ['OF', 'FCOR', 'OR', 'OC', 'SPO', 'IN', 'OBIC', 'PFSPO', 'PFIN', 'CN', 'PFCN', 'SPC', 'PFSPC'],
    },
    placeOfLoading: {
      index: -1,
      // type: 'dropdown',
      // options: PlaceOfLoadingData.getOptions(),
      // label: 'Place of loading',
      docs: ['OF', 'FCOR', 'OR', 'OC', 'SPO', 'IN', 'DN', 'OBIC', 'PFSPO', 'PFIN', 'CN', 'PFCN', 'SPC', 'PFSPC', 'CS'],
      // invoiceType: 'delivery',
    },
    importerInfo: {
      index: -1,
      label: 'Importer Information',
      docs: ['OF', 'FCOR', 'OR', 'OC', 'SPO', 'IN', 'DN', 'OBIC', 'PFSPO', 'PFIN', 'CN', 'PFCN', 'SPC', 'PFSPC'],
      // invoiceType: 'delivery',
    },
    paymentPlan: {
      index: 20, // 4,
      type: 'custom',
      label: 'Payment Plan',
      docs: ['OF', 'FCOR', 'OR', 'OC', 'SPO', 'IN', 'OBIC', 'PFSPO', 'PFIN', 'CN', 'PFCN', 'SPC', 'PFSPC', 'CS'],
    },
    paymentPlanInvoice: {
      index: -1,
      docs: ['OC', 'PL', 'DN', 'IN'],
    },
    termsOfPayment: {
      index: 15, // 3,
      type: 'custom',
      label: 'Terms of Payment',
      docs: ['OF', 'FCOR', 'OR', 'OC', 'SPO', 'IN', 'DN', 'SPC', 'CS'],
    },
    transportation: {
      index: -1,
      type: 'custom',
      label: 'Transportation',
      docs: ['OF', 'FCOR', 'OR', 'OC', 'SPO', 'IN', 'DN', 'OBIC', 'PFSPO', 'PFIN', 'CN', 'PFCN', 'SPC', 'PFSPC', 'CS'],
    },
    weightSection: {
      index: -1,
      type: 'custom',
      label: 'Weight',
      docs: ['OC', 'DN', 'PL', 'IN', 'PFIN'],
    },
    deliveryConditions: {
      index: 10, // 2,
      label: 'Delivery Conditions',
      type: 'custom',
      options: DeliveryConditionsData.getOptions(),
      docs: ['OF', 'FCOR', 'OR', 'SPO', 'OBIC', 'PFSPO', 'OC', 'PFIN', 'IN', 'PL', 'CN', 'SPC', 'PFSPC', 'CS'],
    },
    obicSummary: {
      index: -1,
      // label: 'OBIC summary',
      docs: ['OBIC', 'PFSPO', 'SPO'],
    },
    associatedPfspoList: {
      index: 27, // 5,
      label: 'Associated (PF)SPOs',
      type: 'custom',
      hideOnNonDocs: true,
      docs: ['FCOR', 'OR', 'OC', 'PL', 'DN', 'IN', 'PFIN'],
      // docs: ['OC'],
      readonly: ['FCOR', 'OR', 'PL', 'DN', 'IN', 'PFIN'],
    },
    associatedPfspo: { // line items
      index: -1,
      docs: ['OC', 'PL', 'DN', 'IN', 'PFIN'],
      // docs: ['OC'],
      readonly: ['PL', 'DN', 'IN', 'PFIN'],
      sync: ['OC', 'PL', 'DN'],
    },
    associatedOCList: {
      index: -26, // 5,
      label: 'Associated Order Confirmations',
      type: 'custom',
      hideOnNonDocs: true,
      docs: ['SPO', 'PFSPO'],
      // docs: ['OC'],
      readonly: ['SPO', 'PFSPO'],
    },
    contractCondition: {
      index: 35, // 6,
      template: 'text',
      label: 'Contract Condition',
      docs: ['OF', 'FCOR', 'OR', 'OC', 'SPO', 'OBIC', 'PFSPO', 'SPC', 'PFSPC'],
    },
    generalText: {
      index: 30, // 8,
      template: 'text',
      label: 'Text',
      docs: ['OF', 'FCOR', 'OR', 'OC'],
    },
    warranty: {
      index: 45, // 10,
      template: 'text',
      label: 'Warranty',
      docs: ['OF', 'FCOR', 'OR', 'OC', 'SPO', 'IN', 'OBIC', 'PFSPO', 'PFIN', 'SPC', 'PFSPC'],
    },
    // validity: {
    //   index: 12,
    //   label: 'Validity',
    //   docs: ['OF'],
    // },
    goodsReceived: {
      index: -1,
      type: 'text',
      label: 'Goods Received',
      docs: ['DN'],
    },
    packingConditions: {
      index: 25, // 14,
      type: 'custom',
      label: 'Packing Information',
      // sync: ['OC', 'PL', 'DN', 'IN', 'PFIN'],
      sync: ['OC', 'PL', 'DN'],
      docs: ['FCOR', 'OR', 'OC', 'PL', 'SPO', 'DN', 'OBIC', 'PFSPO', 'PFIN', 'SPC', 'PFSPC', 'CS'],
    },
    packingStatus: {
      sync: ['OC','PL','DN', 'IN', 'PFIN'],
    },
    packingText: {
      sync: ['OC','PL','DN', 'IN', 'PFIN'],
    },
    palletQty: {
      docs: ['SPO', 'PFSPO'],
    },
    boxDetails: {
      index: -1,
      docs: ['PFSPO', 'SPO'],
    },
    labelButtons: {
      index: -1,
      docs: ['OC', 'PL', 'DN', 'IN', 'PFIN'],
    },
    lineItemBoxEditBlock: {
      index: -1,
      docs: ['PFSPO', 'SPO']
    },
    lineItemBoxReadOnlyBlock: {
      index: -1,
      docs: ['OC', 'PL', 'DN', 'IN', 'PFIN', 'CN', 'PFCN', 'SPO', 'PFSPO'],
    },
    documents: {
      index: 55, // 16,
      type: 'file',
      label: 'Related Documents',
      docs: ['OF', 'FCOR', 'OR', 'OC', 'PL', 'SPO', 'IN', 'DN', 'OBIC', 'PFSPO', 'PFIN', 'CN', 'PFCN', 'SPC', 'PFSPC', 'CS'],
    },
    finishedProductPdf: {
      index:  65, // 17,
      type: 'custom',
      label: 'Finished Product PDF Attachments',
      docs: ['OF', 'FCOR', 'OR', 'OC', 'PL', 'SPO', 'IN', 'DN', 'OBIC', 'PFSPO', 'PFIN', 'CN', 'PFCN', 'SPC', 'PFSPC', 'CS'],
    },
    technicalDatasheetPdf: {
      index:  60, // 18,
      type: 'custom',
      label: 'Technical Datasheets',
      docs: ['OF', 'FCOR', 'OR', 'OC', 'PL', 'SPO', 'IN', 'DN', 'OBIC', 'PFSPO', 'PFIN', 'CN', 'PFCN', 'SPC', 'PFSPC', 'CS'],
    },
    documentsInternal: { // TODO: deprecated
      index:  -19,
      type: 'file',
      label: 'Internal Documents',
      docs: [], // 'OF', 'FCOR', 'OR', 'OC', 'PL', 'SPO', 'IN', 'DN', 'OBIC', 'PFSPO', 'PFIN', 'CN', 'PFCN', 'SPC', 'PFSPC'],
    },
    commercialTerms: {
      index: 40, // 20,
      template: 'text',
      label: 'Commercial Terms',
      docs: ['OF', 'FCOR', 'OR', 'OC', 'PL', 'SPO', 'IN', 'DN', 'OBIC', 'PFSPO', 'PFIN', 'CN', 'PFCN', 'SPC', 'PFSPC', 'CS'],
    },
    transferOfOwnership: {
      index: 50, // 22,
      template: 'text',
      label: 'Transfer of Ownership',
      docs: ['OF', 'OC', 'IN'],
    },
    relatedDocuments: {
      index: 85, // 24,
      label: 'Related Documents',
      type: 'custom',
      docs: ['FCOR', 'OR', 'OC', 'IN', 'DN', 'OBIC', 'PFSPO', 'PFIN', 'PFSPC'],
      freeze: ['IN', 'CN', 'PFIN', 'PFCN'],
    },
    changeProtocol: { // TODO: deprecated
      index: -75, // 25,
      label: 'Change Protocol',
      docs: ['OF', 'FCOR', 'OR', 'OC', 'PL', 'SPO', 'IN', 'DN', 'OBIC', 'PFSPO', 'PFIN', 'CN', 'PFCN', 'SPC', 'PFSPC', 'CS'],
    },
    changelog: {
      index: 80, // 28,
      label: 'Change Log',
      type: 'custom',
      docs: ['OF', 'FCOR', 'OR', 'OC', 'PL', 'SPO', 'IN', 'DN', 'OBIC', 'PFSPO', 'PFIN', 'CN', 'PFCN', 'SPC', 'PFSPC', 'CS'],
    },
    changelogLite: {
      index: -1,
      label: '',
      type: 'custom',
      docs: ['OF', 'FCOR', 'OR', 'OC', 'PL', 'SPO', 'IN', 'DN', 'OBIC', 'PFSPO', 'PFIN', 'CN', 'PFCN', 'SPC', 'PFSPC', 'CS'],
    },
    changelogFiles: {
      index: 79, // 28,
      label: 'Current VS Change Log Documents',
      type: 'custom',
      docs: ['OF', 'FCOR', 'OR', 'OC', 'PL', 'SPO', 'IN', 'DN', 'OBIC', 'PFSPO', 'PFIN', 'CN', 'PFCN', 'SPC', 'PFSPC', 'CS'],
    },
    packingImages: {
      index: -1, // 28,
      label: 'Packing Images',
      type: 'custom',
      docs: ['OC', 'DN', 'PL', 'IN'],
      sync: ['OC', 'DN', 'PL', 'IN']
    },
    coverSheet: {
      index: -1,
      type: 'custom',
      docs: ['OF', 'FCOR', 'OR', 'OC', 'PL', 'SPO', 'IN', 'DN', 'OBIC', 'PFSPO', 'PFIN', 'CN', 'PFCN', 'SPC', 'PFSPC', 'CS'],
    },
    internalRemark: {
      index: -90, // 29, // TODO: deprecated
      label: 'Internal Remark',
      docs: ['OF', 'OR', 'OC', 'PL', 'SPO', 'IN', 'DN', 'OBIC', 'PFSPO', 'PFIN', 'CN', 'PFCN', 'SPC', 'PFSPC', 'CS'],
    },
    showInvoiceUpload: {
      index: -1,
      docs: ['IN', 'PFIN', 'CN'],
    },
    transactionReceipt: {
      index: -1,
      label: 'Transaction Receipt', // also used as "upload" field for combined shipment
      forceEnabled: true,
      noPdf: true,
      type: 'custom',
      docs: ['IN','PFIN', 'CS'],
    },
    proveOfDelivery: {
      index: -1,
      label: 'Proof of Delivery',
      forceEnabled: true,
      noPdf: true,
      type: 'custom',
      docs: ['DN', 'IN','PFIN'],
    },
    testCertificate: {
      index: -1,
      label: 'Test Certificate',
      forceEnabled: true,
      noPdf: true,
      type: 'custom',
      docs: ['IN','PFIN'],
    },
    testCertificates: { // plural!, all docs
      index: -1,
      label: 'Test Certificates',
      type: 'custom',
      docs: ['OF', 'FCOR', 'OR', 'OC', 'PL', 'SPO', 'IN', 'DN', 'OBIC', 'PFSPO', 'PFIN', 'CN', 'PFCN', 'SPC', 'PFSPC', 'CS'],
    },
    tcFromPfspo: {
      index: -1,
      label: 'Transfered TC from (PF)SPO',
      type: 'custom',
      docs: ['OF', 'FCOR', 'OR', 'OC', 'PL', 'SPO', 'IN', 'DN', 'OBIC', 'PFSPO', 'PFIN', 'CN', 'PFCN', 'SPC', 'PFSPC', 'CS'],
    },
    accountManager: {
      index: 95, // 86,
      label: 'Account Manager',
      type: 'text',
      disabled: true,
      noPdf: true,
      docs: ['OF', 'FCOR', 'OR', 'OC', 'PL', 'SPO', 'IN', 'DN', 'OBIC', 'PFSPO', 'PFIN', 'CN', 'PFCN', 'SPC', 'PFSPC', 'CS'],
    },
    invoiceRemark: {
      index: 100, // 87,
      label: 'Invoice Remark',
      type: 'text',
      value: 'Invoice number must be stated when settling the invoice via bank transfer. Payments without references will be rejected.',
      hideOnNonDocs: true,
      disabled: true,
      docs: ['IN'],
    },
    lineItemOBIC: {
      index: -1,
      docs: ['OC','DN','IN'],
    },
    issuedBy: {
      index: 105, // 88,
      label: 'Issued by',
      // type: 'dropdown',
      optionsField: 'userOptions',
      disabled: true,
      noPdf: true,
      docs: ['OF', 'FCOR', 'OR', 'OC', 'PL', 'SPO', 'IN', 'DN', 'OBIC', 'PFSPO', 'PFIN', 'CN', 'PFCN', 'SPC', 'PFSPC', 'CS'],
    },
    issuerFooter: {
      index: -1,
      pdfOnly: true,
      docs: ['OF', 'FCOR', 'OR', 'OC', 'PL', 'SPO', 'IN', 'DN', 'OBIC', 'PFSPO', 'PFIN', 'CN', 'PFCN', 'SPC', 'PFSPC', 'CS'],
    },
    bankAddress: {
      index: 110, // 89,
      label: 'Bank',
      type: 'custom',
      rows: 3,
      disabled: true,
      noPdf: true,
      docs: ['OF', 'FCOR', 'OR', 'OC', 'PL', 'SPO', 'IN', 'DN', 'OBIC', 'PFSPO', 'PFIN', 'CN', 'PFCN', 'SPC', 'PFSPC', 'CS'],
    },
    // code: {
    //   index: 90,
    //   label: 'Internal code',
    //   type: 'text',
    //   disabled: true,
    //   noPdf: true,
    //   docs: ['OF', 'FCOR', 'OR', 'OC', 'PL', 'SPO', 'IN', 'DN', 'OBIC', 'PFSPO', 'PFIN', 'CN', 'PFCN', 'SPC', 'PFSPC'],
    // },
    barcodes: {
      index: 115, // 92,
      label: 'Barcodes',
      type: 'custom',
      docs: ['DN'],
      pageBreak: true,
    },
    offerFields: {
      index: -1,
      docs: ['OF'],
    },
    actionPoint: {
      index: -1,
      label: 'Action Point',
      offerField: true,
      type: 'actionPoint',
      docs: ['OF'],
    },
    offerStatus: {
      index: -1,
      label: 'Offer Status',
      offerField: true,
      type: 'offerStatus',
      docs: ['OF'],
    },
    statusComment: {
      index: -1,
      label: 'Status Comment',
      offerField: true,
      type: 'statusComment',
    },
    offerComment: {
      index: -1,
      label: 'Offer Comment',
      offerField: true,
      type: 'text',
    },
    probability: {
      index : -1,
      label: 'Probability',
      offerField: true,
      type: 'percent',
    },
    share: {
      index: -1,
      label: 'Share',
      offerField: true,
      type: 'percent',
    },
    dontShowInReport: {
      index: -1,
      label: 'Don\'t Show in Offer Report',
      offerField: true,
      type: 'checkbox',
    },
    businessStatus: {
      index: -1,
      label: 'Business Status',
      offerField: true,
      type: 'businessStatus',
    },

    // reportDateFields: { // global for exwJapanDate arrivingVieDate customerRequestedDeliveryDate vieDispatchDate actualDeliveryDate
    //   index: -1,
    //   docs: ['PFSPO', 'PFSPC', 'CS'],
    // },

    showReportDates: {
      index: -1,
      docs: ['OC', 'PL', 'SPO', 'IN', 'DN', 'PFSPO', 'PFIN', 'CN', 'PFCN', 'SPC', 'PFSPC', 'CS'],
    },
    fromValidity: {
      index: -1,
      type: 'reportDate',
      label: 'Valid from',
      docs: ['FCOR']
    },
    offerValidity: {
      index: -1,
      type: 'reportDate',
      label: 'Valid to',
      docs: ['OF', 'TOF', 'FCOR'],
      mandatory: ['OF'],
    },
    latestDeliveryDate: {
      index: -1,
      type: 'reportDate',
      label: 'Latest Delivery',
      docs: ['OF', 'TOF'],
    },
    directDelivery: {
      index: -1,
      label: 'Direct Delivery',
      // docs: ['OC'],
      // readonly: ['DN', 'PL', 'IN', 'SPO', 'OBIC', 'PFSPO'],
      docs: ['SPO', 'OBIC', 'PFSPO'],
      readonly: ['OC', 'DN', 'PL', 'IN'],
    },
    pfspoWithShipment: {
      index: -1,
      label: '(PF)SPO with Shipment',
      // docs: ['OBIC', 'PFSPO', 'SPO'],
      docs: ['PFSPO', 'SPO'],
    },
    requestedExwDate: { // Take over from related PFSPO → CS
      index: -1,
      type: 'reportDate',
      label: 'Requested EXW Date', // 'EXW Japan Date',
      // docs: ['SPO', 'PFSPO', 'CS'],
      docs: ['CS', 'PFSPO'],
      readonly: ['OC', 'DN', 'PL', 'IN', 'PFIN', 'SPO', 'PFSPO'],
      getFromCS: ['OC', 'DN', 'PL', 'IN', 'PFIN', 'SPO', 'PFSPO'],
    },
    exwJapanDate: { // Take over from related PFSPO → CS
      index: -1,
      type: 'reportDate',
      label: 'Actual EXW Date', // 'EXW Japan Date',
      // docs: ['SPO', 'PFSPO', 'CS'],
      docs: ['CS', 'PFSPO'],
      readonly: ['OC', 'DN', 'PL', 'IN', 'PFIN', 'SPO', 'PFSPO'],
      getFromCS: ['OC', 'DN', 'PL', 'IN', 'PFIN', 'SPO', 'PFSPO'],
    },
    transferTime1: { // Take over from related PFSPO → CS
      index: -1,
      label: 'Transfer Time 1',
      type: 'reportNumber',
      docs: ['CS', 'PFSPO'],
      readonly: ['OC', 'DN', 'PL', 'IN', 'PFIN', 'SPO', 'PFSPO'],
      getFromCS: ['OC', 'DN', 'PL', 'IN', 'PFIN', 'SPO', 'PFSPO'],
    },
    arrivingVieDate: { // Take over from related PFSPO → CS
      index: -1,
      type: 'reportDate',
      label: 'Arriving VIE Date',
      // docs: ['SPO', 'PFSPO', 'CS'],
      docs: ['CS', 'PFSPO'],
      readonly: ['OC', 'DN', 'PL', 'IN', 'PFIN', 'SPO', 'PFSPO'],
      getFromCS: ['OC', 'DN', 'PL', 'IN', 'PFIN', 'SPO', 'PFSPO'],
    },
    maxArrivingVieDate: {
      index: -1,
      type: 'reportDate',
      label: 'Arriving VIE Date',
      docs: [],
      // readonly: ['OC', 'DN', 'PL', 'IN', 'PFIN', 'SPO', 'PFSPO'],
    },
    vieDispatchDate: {
      index: -1,
      type: 'reportDate',
      label: 'Dispatch Date to Final Customer', // 'VIE Dispatch Date',
      // docs: ['OF', 'FCOR', 'OR', 'OC', 'PL', 'SPO', 'IN', 'DN', 'PFSPO', 'PFIN', 'CN', 'PFCN', 'SPC', 'PFSPC', 'CS'],
      docs: ['OC'],
      readonly: ['SPO', 'PFSPO', 'DN', 'PL', 'IN', 'PFIN', 'CS'],
      mandatory: ['OC'],
      sync: ['OC', 'DN', 'PL', 'IN', 'PFIN'],
      getFromOC: ['PFSPO', 'SPO'],
    },
    dispatchDateUnconfirmed: {
      index: -1,
      docs: ['OC'],
    },
    expectedNextInvoiceDate: { // default: vieDispatchDate
      index: -1,
      label: 'Expected Next Invoice Date',
      type: 'reportDate',
      docs: ['OC'],
      readonly: ['DN', 'PL', 'IN', 'PFIN'],
    },
    transferTime2: {
      index: -1,
      label: 'Transfer Time 2',
      type: 'reportNumber',
      readonly: ['SPO', 'PFSPO', 'DN', 'PL', 'IN', 'PFIN'],
      docs: ['OC'],
      sync: ['OC', 'DN', 'PL', 'IN', 'PFIN'],
    },
    etaCustomerDate: {
      index: -1,
      label: 'ETA Customer Date',
      type: 'reportDate',
      readonly: ['SPO', 'PFSPO', 'DN', 'PL', 'IN', 'PFIN'],
      mandatory: ['OC'],
      docs: ['OC'],
    },
    lineItemDateOfDelivery: { // customer requested delivery date in line items
      index: -1,
      label: 'Date of Delivery',
      docs: ['OF', 'FCOR', 'OR'],
    },
    lineItemOrderPos: {
      index: -1,
      label: 'PO',
      docs: ['OC'],
      readonly: ['DN', 'PL', 'IN', 'PFIN'],
    },
    optionalLineItems: {
      index: -1,
      docs: ['OF'],
    },
    customerRequestedDeliveryDate: {
      index: -1,
      type: 'reportDate',
      label: 'Customer Requested Delivery Date',
      // docs: ['OF', 'FCOR', 'OR', 'OC', 'PL', 'SPO', 'IN', 'DN', 'PFSPO', 'PFIN', 'CN', 'PFCN', 'SPC', 'PFSPC', 'CS'],
      // docs: ['FCOR', 'OR','OC', 'DN', 'PL', 'IN', 'PFIN'],
      docs: ['OC'],
      readonly: ['PFSPO', 'SPO', 'DN', 'PL', 'IN', 'PFIN'],
      mandatory: ['OC'],
    },
    customerAcceptedDeliveryDate: {
      index: -1,
      type: 'reportDate',
      label: 'Customer Accepted Delivery Date',
      // docs: ['OF', 'FCOR', 'OR', 'OC', 'PL', 'SPO', 'IN', 'DN', 'PFSPO', 'PFIN', 'CN', 'PFCN', 'SPC', 'PFSPC', 'CS'],
      docs: ['OC'],
      readonly: ['PFSPO', 'SPO', 'DN', 'PL', 'IN', 'PFIN'],
      mandatory: ['OC'],
    },
    actualDeliveryDate: {
      index: -1,
      type: 'reportDate',
      label: 'Actual Delivery Date',
      readonly: ['SPO', 'PFSPO'],
      // docs: ['OC', 'DN', 'PL', 'IN', 'PFIN'],
      docs: ['IN'],
    },
    readyForPickupDate: {
      index:-1,
      type: 'reportDate',
      label: 'Ready for Pick-up',
      docs: ['SPO', 'PFSPO'],
      readonly: ['CS'],
    },

    csTable: {
      docs: ['OC', 'PL', 'DN', 'IN', 'PFIN'],
    },
    pfspoTable: {
      docs: ['CS'],
    },
    ocTable: {
      docs: ['SPO', 'PFSPO'],
    },

    exwDate: {
      index: -1,
      type: 'displayDate',
      checkedDocs: ['CS', 'PFSPO', 'SPO'],
      docs: ['OC', 'PL', 'DN', 'IN', 'PFIN', 'CS', 'PFSPO', 'SPO'],
      readonly: ['OC', 'PL', 'DN', 'IN', 'PFIN', 'CS', 'PFSPO', 'SPO'],
    },
    dateOfVieArrival: {
      index: -1,
      type: 'displayDate',
      checkedDocs: ['CS', 'PFSPO', 'SPO'],
      docs: ['OC', 'PL', 'DN', 'IN', 'PFIN', 'CS', 'PFSPO', 'SPO'],
      readonly: ['OC', 'PL', 'DN', 'IN', 'PFIN', 'CS', 'PFSPO', 'SPO'],
    },
    dateOfDispatch: {
      index: -1,
      type: 'displayDate',
      checkedDocs: ['OC', 'PL', 'DN', 'IN', 'PFIN', 'CS', 'PFSPO', 'SPO'],
      docs: ['OC', 'PL', 'DN', 'IN', 'PFIN', 'CS', 'PFSPO', 'SPO'],
      readonly: ['OC', 'PL', 'DN', 'IN', 'PFIN', 'CS', 'PFSPO', 'SPO'],
    },
    dateOfDelivery: {
      index: -1,
      type: 'displayDate',
      checkedDocs: ['CS', 'PFSPO', 'SPO'],
      docs: ['OC', 'PL', 'DN', 'IN', 'PFIN', 'CS', 'PFSPO', 'SPO'],
      readonly: ['OC', 'PL', 'DN', 'IN', 'PFIN', 'CS', 'PFSPO', 'SPO'],
    },
    dateOfPickup: {
      index: -1,
      type: 'displayDate',
      checkedDocs: ['PFSPO', 'SPO'],
      docs: ['PFSPO', 'SPO'],
      readonly: ['PFSPO', 'SPO'],
    },
    dateOfService: {
      index: -1,
      type: 'displayDate',
      checkedDocs: ['FCOR', 'OR', 'OC', 'PL', 'DN', 'IN', 'PFIN', 'CS', 'PFSPO', 'SPO'],
      docs: ['OC', 'PL', 'DN', 'IN', 'PFIN', 'CS', 'PFSPO', 'SPO'],
      // readonly: ['OC', 'PL', 'DN', 'IN', 'PFIN', 'CS', 'PFSPO', 'SPO'],
      readonly: ['PL', 'DN', 'IN', 'PFIN', 'CS', 'PFSPO'],
    },
    // displayDatesStore: {
    //   index: -1,
    //   docs: ['SPC'],
    // },


    transportStatus: {
      index: -1,
      label: 'Transport status',
      // docs: ['OC', 'PL', 'DN', 'IN', 'PFIN', 'CS', 'PFSPO', 'SPO'],
      docs: ['CS', 'PFSPO', 'SPO'],
    },
    spoStatus: {
      index: -1,
      label: 'SPO status',
      docs: ['PFSPO', 'SPO'],
    },
    combinedShipment: {
      index: -1,
      docs: ['CS'],
    },
    combinedShipmentButton: {
      index: -1,
      docs: ['PFSPO', 'SPO'],
    },
    completelyInvoiced: {
      index: -1,
      docs: ['OC'],
      sync: ['OC', 'PL', 'DN']
    },
    transferPrices: {
      index : -1,
      docs: ['OF'],
    },
    pricesFromDeliveryDate: {
      index: -1,
      docs: ['TOF', 'OF', 'FCOR', 'OR'],
    },
    poCheck: {
      index: -1,
      docs: ['FCOR', 'OR'],
    },
  };

  static checkVisible(section: string, compilationType: number): boolean {
    if (compilationType == null || +compilationType <= 0) return false;
    const s = DocConfig.sections[section];
    return s?.docs ? s.docs.includes(CompilationTypeData.get(+compilationType).code) : true;
  }

  static checkReadOnly(section: string, compilationType: number): boolean {
    if (compilationType == null || +compilationType <= 0) return false;
    const s = DocConfig.sections[section];
    return s?.readonly ? s.readonly.includes(CompilationTypeData.get(+compilationType).code) : false;
  }

  static getSections() {
    return DocConfig.sections;
  }

  static getArray() {
    return Object.entries(DocConfig.getSections()).map(m => ({key: m[0] as string, value: m[1] as any}))
  }

  static get(section: string) {
    const ret = DocConfig.sections[section];
    ret.name = section;
    return ret;
  }

  static isMandatory(section: string, compilationType: number) {
    return DocConfig.get(section).mandatory
      .includes(CompilationTypeData.get(+compilationType).code);
  }

  static getFromCS(section: string, compilationType: number) {
    const s = DocConfig.get(section).getFromCS;
    return s && s.includes(CompilationTypeData.get(+compilationType).code);
  }

  static isSalePrice(docType: number) {
    return !['SPO', 'OBIC', 'PFSPO', 'SPC', ['PFSPC']].includes(CompilationTypeData.get(+docType).code);
  }

  static reportDateFieldOptions = [
    { id: 'issueDate', name: 'Issue Date' },
    ...DocConfig.getArray().filter(f => 
      f.value.type === 'reportDate' 
      && f.key !== 'maxArrivingVieDate'
    ).map(m => ({ id: m.key, name: m.value.label })),
  ]
}

export class CountryCodes {
  private static list = [
    {'name': 'Afghanistan', 'code': 'AF'},
    {'name': 'Åland Islands', 'code': 'AX'},
    {'name': 'Albania', 'code': 'AL'},
    {'name': 'Algeria', 'code': 'DZ'},
    {'name': 'American Samoa', 'code': 'AS'},
    {'name': 'Andorra', 'code': 'AD'},
    {'name': 'Angola', 'code': 'AO'},
    {'name': 'Anguilla', 'code': 'AI'},
    {'name': 'Antarctica', 'code': 'AQ'},
    {'name': 'Antigua and Barbuda', 'code': 'AG'},
    {'name': 'Argentina', 'code': 'AR'},
    {'name': 'Armenia', 'code': 'AM'},
    {'name': 'Aruba', 'code': 'AW'},
    {'name': 'Australia', 'code': 'AU'},
    {'name': 'Austria', 'code': 'AT'},
    {'name': 'Azerbaijan', 'code': 'AZ'},
    {'name': 'Bahamas', 'code': 'BS'},
    {'name': 'Bahrain', 'code': 'BH'},
    {'name': 'Bangladesh', 'code': 'BD'},
    {'name': 'Barbados', 'code': 'BB'},
    {'name': 'Belarus', 'code': 'BY'},
    {'name': 'Belgium', 'code': 'BE'},
    {'name': 'Belize', 'code': 'BZ'},
    {'name': 'Benin', 'code': 'BJ'},
    {'name': 'Bermuda', 'code': 'BM'},
    {'name': 'Bhutan', 'code': 'BT'},
    {'name': 'Bolivia', 'code': 'BO'},
    {'name': 'Bosnia and Herzegovina', 'code': 'BA'},
    {'name': 'Botswana', 'code': 'BW'},
    {'name': 'Bouvet Island', 'code': 'BV'},
    {'name': 'Brazil', 'code': 'BR'},
    {'name': 'British Indian Ocean Territory', 'code': 'IO'},
    {'name': 'Brunei Darussalam', 'code': 'BN'},
    {'name': 'Bulgaria', 'code': 'BG'},
    {'name': 'Burkina Faso', 'code': 'BF'},
    {'name': 'Burundi', 'code': 'BI'},
    {'name': 'Cambodia', 'code': 'KH'},
    {'name': 'Cameroon', 'code': 'CM'},
    {'name': 'Canada', 'code': 'CA'},
    {'name': 'Cape Verde', 'code': 'CV'},
    {'name': 'Cayman Islands', 'code': 'KY'},
    {'name': 'Central African Republic', 'code': 'CF'},
    {'name': 'Chad', 'code': 'TD'},
    {'name': 'Chile', 'code': 'CL'},
    {'name': 'China', 'code': 'CN'},
    {'name': 'Christmas Island', 'code': 'CX'},
    {'name': 'Cocos (Keeling) Islands', 'code': 'CC'},
    {'name': 'Colombia', 'code': 'CO'},
    {'name': 'Comoros', 'code': 'KM'},
    {'name': 'Congo', 'code': 'CG'},
    {'name': 'Congo, The Democratic Republic of the', 'code': 'CD'},
    {'name': 'Cook Islands', 'code': 'CK'},
    {'name': 'Costa Rica', 'code': 'CR'},
    {'name': 'Cote D\'Ivoire', 'code': 'CI'},
    {'name': 'Croatia', 'code': 'HR'},
    {'name': 'Cuba', 'code': 'CU'},
    {'name': 'Cyprus', 'code': 'CY'},
    {'name': 'Czech Republic', 'code': 'CZ'},
    {'name': 'Denmark', 'code': 'DK'},
    {'name': 'Djibouti', 'code': 'DJ'},
    {'name': 'Dominica', 'code': 'DM'},
    {'name': 'Dominican Republic', 'code': 'DO'},
    {'name': 'Ecuador', 'code': 'EC'},
    {'name': 'Egypt', 'code': 'EG'},
    {'name': 'El Salvador', 'code': 'SV'},
    {'name': 'Equatorial Guinea', 'code': 'GQ'},
    {'name': 'Eritrea', 'code': 'ER'},
    {'name': 'Estonia', 'code': 'EE'},
    {'name': 'Ethiopia', 'code': 'ET'},
    {'name': 'Falkland Islands (Malvinas)', 'code': 'FK'},
    {'name': 'Faroe Islands', 'code': 'FO'},
    {'name': 'Fiji', 'code': 'FJ'},
    {'name': 'Finland', 'code': 'FI'},
    {'name': 'France', 'code': 'FR'},
    {'name': 'French Guiana', 'code': 'GF'},
    {'name': 'French Polynesia', 'code': 'PF'},
    {'name': 'French Southern Territories', 'code': 'TF'},
    {'name': 'Gabon', 'code': 'GA'},
    {'name': 'Gambia', 'code': 'GM'},
    {'name': 'Georgia', 'code': 'GE'},
    {'name': 'Germany', 'code': 'DE'},
    {'name': 'Ghana', 'code': 'GH'},
    {'name': 'Gibraltar', 'code': 'GI'},
    {'name': 'Greece', 'code': 'GR'},
    {'name': 'Greenland', 'code': 'GL'},
    {'name': 'Grenada', 'code': 'GD'},
    {'name': 'Guadeloupe', 'code': 'GP'},
    {'name': 'Guam', 'code': 'GU'},
    {'name': 'Guatemala', 'code': 'GT'},
    {'name': 'Guernsey', 'code': 'GG'},
    {'name': 'Guinea', 'code': 'GN'},
    {'name': 'Guinea-Bissau', 'code': 'GW'},
    {'name': 'Guyana', 'code': 'GY'},
    {'name': 'Haiti', 'code': 'HT'},
    {'name': 'Heard Island and Mcdonald Islands', 'code': 'HM'},
    {'name': 'Holy See (Vatican City State)', 'code': 'VA'},
    {'name': 'Honduras', 'code': 'HN'},
    {'name': 'Hong Kong', 'code': 'HK'},
    {'name': 'Hungary', 'code': 'HU'},
    {'name': 'Iceland', 'code': 'IS'},
    {'name': 'India', 'code': 'IN'},
    {'name': 'Indonesia', 'code': 'ID'},
    {'name': 'Iran, Islamic Republic Of', 'code': 'IR'},
    {'name': 'Iraq', 'code': 'IQ'},
    {'name': 'Ireland', 'code': 'IE'},
    {'name': 'Isle of Man', 'code': 'IM'},
    {'name': 'Israel', 'code': 'IL'},
    {'name': 'Italy', 'code': 'IT'},
    {'name': 'Jamaica', 'code': 'JM'},
    {'name': 'Japan', 'code': 'JP'},
    {'name': 'Jersey', 'code': 'JE'},
    {'name': 'Jordan', 'code': 'JO'},
    {'name': 'Kazakhstan', 'code': 'KZ'},
    {'name': 'Kenya', 'code': 'KE'},
    {'name': 'Kiribati', 'code': 'KI'},
    {'name': 'Korea, Democratic People\'s Republic of', 'code': 'KP'},
    {'name': 'Korea, Republic of', 'code': 'KR'},
    {'name': 'Kuwait', 'code': 'KW'},
    {'name': 'Kyrgyzstan', 'code': 'KG'},
    {'name': 'Lao People\'s Democratic Republic', 'code': 'LA'},
    {'name': 'Latvia', 'code': 'LV'},
    {'name': 'Lebanon', 'code': 'LB'},
    {'name': 'Lesotho', 'code': 'LS'},
    {'name': 'Liberia', 'code': 'LR'},
    {'name': 'Libyan Arab Jamahiriya', 'code': 'LY'},
    {'name': 'Liechtenstein', 'code': 'LI'},
    {'name': 'Lithuania', 'code': 'LT'},
    {'name': 'Luxembourg', 'code': 'LU'},
    {'name': 'Macao', 'code': 'MO'},
    {'name': 'Macedonia, The Former Yugoslav Republic of', 'code': 'MK'},
    {'name': 'Madagascar', 'code': 'MG'},
    {'name': 'Malawi', 'code': 'MW'},
    {'name': 'Malaysia', 'code': 'MY'},
    {'name': 'Maldives', 'code': 'MV'},
    {'name': 'Mali', 'code': 'ML'},
    {'name': 'Malta', 'code': 'MT'},
    {'name': 'Marshall Islands', 'code': 'MH'},
    {'name': 'Martinique', 'code': 'MQ'},
    {'name': 'Mauritania', 'code': 'MR'},
    {'name': 'Mauritius', 'code': 'MU'},
    {'name': 'Mayotte', 'code': 'YT'},
    {'name': 'Mexico', 'code': 'MX'},
    {'name': 'Micronesia, Federated States of', 'code': 'FM'},
    {'name': 'Moldova, Republic of', 'code': 'MD'},
    {'name': 'Monaco', 'code': 'MC'},
    {'name': 'Mongolia', 'code': 'MN'},
    {'name': 'Montenegro', 'code': 'ME'},
    {'name': 'Montserrat', 'code': 'MS'},
    {'name': 'Morocco', 'code': 'MA'},
    {'name': 'Mozambique', 'code': 'MZ'},
    {'name': 'Myanmar', 'code': 'MM'},
    {'name': 'Namibia', 'code': 'NA'},
    {'name': 'Nauru', 'code': 'NR'},
    {'name': 'Nepal', 'code': 'NP'},
    {'name': 'Netherlands', 'code': 'NL'},
    {'name': 'Netherlands Antilles', 'code': 'AN'},
    {'name': 'New Caledonia', 'code': 'NC'},
    {'name': 'New Zealand', 'code': 'NZ'},
    {'name': 'Nicaragua', 'code': 'NI'},
    {'name': 'Niger', 'code': 'NE'},
    {'name': 'Nigeria', 'code': 'NG'},
    {'name': 'Niue', 'code': 'NU'},
    {'name': 'Norfolk Island', 'code': 'NF'},
    {'name': 'Northern Mariana Islands', 'code': 'MP'},
    {'name': 'Norway', 'code': 'NO'},
    {'name': 'Oman', 'code': 'OM'},
    {'name': 'Pakistan', 'code': 'PK'},
    {'name': 'Palau', 'code': 'PW'},
    {'name': 'Palestinian Territory, Occupied', 'code': 'PS'},
    {'name': 'Panama', 'code': 'PA'},
    {'name': 'Papua New Guinea', 'code': 'PG'},
    {'name': 'Paraguay', 'code': 'PY'},
    {'name': 'Peru', 'code': 'PE'},
    {'name': 'Philippines', 'code': 'PH'},
    {'name': 'Pitcairn', 'code': 'PN'},
    {'name': 'Poland', 'code': 'PL'},
    {'name': 'Portugal', 'code': 'PT'},
    {'name': 'Puerto Rico', 'code': 'PR'},
    {'name': 'Qatar', 'code': 'QA'},
    {'name': 'Reunion', 'code': 'RE'},
    {'name': 'Romania', 'code': 'RO'},
    {'name': 'Russian Federation', 'code': 'RU'},
    {'name': 'RWANDA', 'code': 'RW'},
    {'name': 'Saint Helena', 'code': 'SH'},
    {'name': 'Saint Kitts and Nevis', 'code': 'KN'},
    {'name': 'Saint Lucia', 'code': 'LC'},
    {'name': 'Saint Pierre and Miquelon', 'code': 'PM'},
    {'name': 'Saint Vincent and the Grenadines', 'code': 'VC'},
    {'name': 'Samoa', 'code': 'WS'},
    {'name': 'San Marino', 'code': 'SM'},
    {'name': 'Sao Tome and Principe', 'code': 'ST'},
    {'name': 'Saudi Arabia', 'code': 'SA'},
    {'name': 'Senegal', 'code': 'SN'},
    {'name': 'Serbia', 'code': 'RS'},
    {'name': 'Seychelles', 'code': 'SC'},
    {'name': 'Sierra Leone', 'code': 'SL'},
    {'name': 'Singapore', 'code': 'SG'},
    {'name': 'Slovakia', 'code': 'SK'},
    {'name': 'Slovenia', 'code': 'SI'},
    {'name': 'Solomon Islands', 'code': 'SB'},
    {'name': 'Somalia', 'code': 'SO'},
    {'name': 'South Africa', 'code': 'ZA'},
    {'name': 'South Georgia and the South Sandwich Islands', 'code': 'GS'},
    {'name': 'Spain', 'code': 'ES'},
    {'name': 'Sri Lanka', 'code': 'LK'},
    {'name': 'Sudan', 'code': 'SD'},
    {'name': 'Suriname', 'code': 'SR'},
    {'name': 'Svalbard and Jan Mayen', 'code': 'SJ'},
    {'name': 'Swaziland', 'code': 'SZ'},
    {'name': 'Sweden', 'code': 'SE'},
    {'name': 'Switzerland', 'code': 'CH'},
    {'name': 'Syrian Arab Republic', 'code': 'SY'},
    {'name': 'Taiwan, Province of China', 'code': 'TW'},
    {'name': 'Tajikistan', 'code': 'TJ'},
    {'name': 'Tanzania, United Republic of', 'code': 'TZ'},
    {'name': 'Thailand', 'code': 'TH'},
    {'name': 'Timor-Leste', 'code': 'TL'},
    {'name': 'Togo', 'code': 'TG'},
    {'name': 'Tokelau', 'code': 'TK'},
    {'name': 'Tonga', 'code': 'TO'},
    {'name': 'Trinidad and Tobago', 'code': 'TT'},
    {'name': 'Tunisia', 'code': 'TN'},
    {'name': 'Turkey', 'code': 'TR'},
    {'name': 'Turkmenistan', 'code': 'TM'},
    {'name': 'Turks and Caicos Islands', 'code': 'TC'},
    {'name': 'Tuvalu', 'code': 'TV'},
    {'name': 'Uganda', 'code': 'UG'},
    {'name': 'Ukraine', 'code': 'UA'},
    {'name': 'United Arab Emirates', 'code': 'AE'},
    {'name': 'United Kingdom', 'code': 'GB'},
    {'name': 'United States', 'code': 'US'},
    {'name': 'United States Minor Outlying Islands', 'code': 'UM'},
    {'name': 'Uruguay', 'code': 'UY'},
    {'name': 'Uzbekistan', 'code': 'UZ'},
    {'name': 'Vanuatu', 'code': 'VU'},
    {'name': 'Venezuela', 'code': 'VE'},
    {'name': 'Vietnam', 'code': 'VN'},
    {'name': 'Virgin Islands, British', 'code': 'VG'},
    {'name': 'Virgin Islands, U.S.', 'code': 'VI'},
    {'name': 'Wallis and Futuna', 'code': 'WF'},
    {'name': 'Western Sahara', 'code': 'EH'},
    {'name': 'Yemen', 'code': 'YE'},
    {'name': 'Zambia', 'code': 'ZM'},
    {'name': 'Zimbabwe', 'code': 'ZW'},
  ];

  public static getOptions() {
    return CountryCodes.list.map(m => ({ id: m.name, name: m.name }));
  }
}


