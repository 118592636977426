import { LineItemModel } from './lineItem';
import { FileModel, BusinessModel, UserModel } from '..';
import { ChangelogEntry } from '../../pages/erp/shared/changelog/changelog.component';
import { IDocumentTree } from '../../@core/service/tree.service';

export enum CacheType {
  ocs,
  spos,
  docs,
  doctype,
  ocSpoMap,
  spoOcMap,
}

export enum EPackingStatus {
  notReadyForPacking = 0,
  readyForPacking = 1,
  readyForDispatch,
  dispatched,
  delivered,
  completed,
}
export function packingStatusName(s: EPackingStatus) {
  return [null, 'Ready for Packing', 'Ready for Dispatch', 'Dispatched'][s];
}

export class DeductibleItem {
  ref: number;
  description: string;
  // businessUnit: BusinessUnitModel;
  baseProduct: string; // BaseProduct Code
  value: number;
}

export class PaymentPlanInvoice {
  code: string; // invoice #
  paymentType: number; // InvoicePaymentData (DP/PP)
  issueDate: Date;
  baseProduct: string; // BaseProduct Code
  net: number;
  // currency: string;
  paymentStatus: number // PaymentStatusData 
  paymentDate: Date;
}

export class PaymentPlanItem {
  percent: string;
  textPrefix: string;
  scheduleDetails: string;
  text: string;
  termsOfPayment: string;
  invoice: string;
  paid: string;
  invoices: PaymentPlanInvoice[] = [];
}

export class PaymentPlan {
  items: PaymentPlanItem[] = [];
  recipientUID: string;
  text: string;
  termsOfPayment: string;
}

export class DeliveryConditions {
  incoterms: string;
  place: string;
  country: string;
}

export class PackingConditionItem {
  boxes: number[];
  numberOfBoxes: number;
  typeOfBox: string;
  csNr: string;
  tcNr: string;
  quantity: number;
  netWeight: number;
}

export interface IPalletRef {
  code: string;
  pos: any;
  palId: string;
  el: number;
  of: number;
  refCode: string;
  refPos: any;
  refPalId: string;
  refEl: number;
  refOf: number;
  numBoxes: number;
  netWeight: number;
  weight: number;
  weightOverride: number;
}

export class PackingConditions {
  lineItemIdx: number;
  packing: string; // legacy
  type: string; // legacy
  palettType: string; // legacy TODO: replace with palletType
  palletType: string;
  packingType: string;
  temperatureSticker: boolean;
  thermoShield: boolean;
  edgeProtection: boolean;
  relabelling: boolean;
  freeText: string;
  numberOfBoxes: number; // legacy
  typeOfBox: string; // legacy
  dimensions: string;
  netWeight: number;
  weight: number;
  weightOverride: number;
  heightOverride: number;
  trackingCode: string; // legacy => remove
  palNr: string;
  palId: string;
  palRef: string;
  csNr: string; // legacy
  tcNr: string; // legacy
  items: PackingConditionItem[];
}

export class DocumentFooter {
  address: string;
  registry: string;
  bank: string;
}

export class ShipmentLineItem {
  document: CompilationModel;
  code: string;
}

export class TransportRun {
  transportStatus: number = 1;
  spoStatus: number = 0;
  availabilityStatus: number =  1;
  relatedCS: string; // dummy, not in db
  csDoc: CompilationModel; // dummy, not in db
}

export class InvoiceUpload {
  completed: boolean = false;
  files: FileModel[] = [];
}

export enum EBusinessStatus {
  default = undefined,
  new = 11,
  active = 12,
}

export class OfferData {
  actionPoint: number;
  offerStatus: number;
  offerComment: string;
  statusComment: number;
  probability: number;
  share: number;
  updatedAt: Date;
  user: UserModel;
  dontShowInReport: boolean = false;
  businessStatus: EBusinessStatus;
}

export class FirstDate {
  issueDate: Date;
  exwJapanDate: Date;
}

export class DueEntry {
  public user: UserModel;
  public dueDate: Date;
  public dueText: string;
  public lastDueUpdate: Date;
  public nextPaymentDate: Date;
}

export class BoxLabelConfig {
  fontSize = 18;
  textCentered = false;
  remarks = '';
  mode = 1;
}

export class SpoPayment {
  spInvoice: string;
  spInvoiceDate: Date;
  spInvoiceValue: number;
  paymentDate: Date;
  fiscalYear: number;
  spoInvoiceUpload: FileModel;
  transactionReceipt: FileModel;
}

export class CompilationModel {
  public _id?: string;

  public code: string; // documentNumber
  public version: number;
  public draft: boolean;
  public owner: UserModel;
  public files: FileModel[];
  public changelog: string | ChangelogEntry[];
  public editCount: number = 0;
  public draftCode: string;

  public type: number;    // id of CompilationTypeData in compilationData.ts
  public headingType: number;
  public parent: string;  // parent code / documentNumber
  public lineItems: LineItemModel[];
  public optionalLineItems: LineItemModel[]; // optional line items for offers
  public deductibleItems: DeductibleItem[];
  public issuer: number;  // id of IssuerData in compilationData.ts
  public accountManager: string; // ContactModel;
  public sampleRequest: boolean;
  public completelyInvoiced: boolean;
  public notInInvoiceReport: boolean;
  public notInOrderReport: boolean;
  public orderAcknoledgement: boolean;
  public isInternalDocument: boolean;
  public creditNoteReason: string;
  public recipient: BusinessModel; // ContactModel;
  // public manufacturerServiceprovider: number;   // id of ImporterData in compilationData.ts
  // public importer: number;                      // id of ImporterData in compilationData.ts
  public receptionHours: string;
  public holidays: Date[];
  public internal: string;
  public internalRemark: string;
  public machineSN: string;
  // public paymentConditions: number;       // id of PaymentConditionData in compilationData.ts
  public deliveryConditions: DeliveryConditions; // number;      // id of DeliveryConditionData in compilationData.ts
  // public meansOfTransportation: number;   // id of MeansOfTransportationData in compilationData.ts TODO: ===> legacy remove!
  public meansOfTransport: string;
  public taxHint: string; // number;                 // id of TaxHintData in compilationData.ts
  public issuerAddress: string;
  public issuerContact: string;
  public pfspoInDisclaimer: string;
  public recipientAddress: string;
  public invoiceSendTo: string;
  public recipientContact: string;
  public deliveryRecipient: BusinessModel;
  public deliveryContact: string;
  public deliveryAddress: string;
  public pickUpRecipient: BusinessModel;
  public pickUpContact: string;
  public pickUpAddress: string;
  public serviceRecipient: BusinessModel;
  public serviceProvided: string;
  public manufacturerRecipient: BusinessModel;
  public manufacturerContact: string;
  public manufacturerServiceproviderAddress: string;
  public importerRecipient: BusinessModel;
  public importerAddress: string;
  public placeOfLoadingRecipient: BusinessModel;
  public placeOfLoadingContact: string;
  public placeOfLoading: string;
  public customerReference: BusinessModel;
  public customerName: string;
  public customerDocumentNumber: string;
  public customerDocumentDate: Date;
  public customerPoPosition: string;
  public partialDelivery: { el: 0, of: 0 };
  public issueDate: Date;
  public dueInfo: DueEntry[];
  public dueDate: Date;
  public dueText: string;
  public dueUser: UserModel;
  public lastDueUpdate: Date;
  public nextPaymentDate: Date;
  public deliveryDate: Date;
  public dateOfDispatch: Date;
  public dateOfService: Date;
  public dateOfDelivery: Date;
  public exwDate: Date;
  public dateOfVieArrival: Date;
  public dateOfPickup: Date;
  public legacyDateOfDispatch: string;
  public legacyDateOfService: string;
  public legacyDateOfDelivery: string;
  public legacyExwDate: string;
  public legacyDateOfVieArrival: string;
  public dateText: string;
  public numberOfCollies: string;
  public netTotal: string;

  public get lineItemsTotal() {
    return this.lineItems.reduce((a, li) => {
      let net = li.piecePrice * li.quantity;
      if ((this.invoicePayment === 1 || this.invoicePayment === 2) && this.invoicePercent > 0) {
        net = net * this.invoicePercent / 100;
      }
      if ((li.discount > 0)) {
        net = net * (100 - li.discount) / 100;
      }
      return a + net;
    }, 0)
  }

  public get deductibleTotal() {
    console.log('sum deductibleTotal', this.deductibleItems);
    return this.deductibleItems?.reduce((a, di) => a + di.value, 0) || 0;
  }

  public get spoINtotal() {
    console.log('sum spoInTotal', this.spoPayment)
    return this.spoPayment?.reduce((a, spin) => a + spin.spInvoiceValue, 0) || 0;
  }

  public get paymentPlanInvoicesTotal() {
    return this.paymentPlan?.items?.flatMap(m => m.invoices)?.reduce((a, c) => {
      console.log('ppIn', this.code, a, c);
      return a + (+c?.net || 0)
    }, 0) || 0;
  }

  public get sumTotal() {
    return this.lineItemsTotal + this.deductibleTotal + this.paymentPlanInvoicesTotal;
  }

  public vatTotal: string;
  public totalToBePaid: string;
  public additionalText: string;
  public documentStatus: number;          // id of DocumentStatusData in compilationData.ts
  public paymentStatus: number;           // id of PaymentStatusData in compilationData.ts
  public paymentDate: Date;
  public pfStatus: number;                // id of PfStatusData in compilationData.ts
  public invoiceStatus: number; // TODO: paymentStatus === invoiceStatus => cleanup
  // public offerStatus: number;
  public offerData: OfferData;
  public dateCreated: Date;
  public dateUpdated: Date;
  public cancelReason: string;
  public netWeightOverride: string; // TODO: unused? remove?
  public issuedBy: UserModel;
  // public completed: boolean;
  public exwConfirmed: boolean;
  public enRoute: boolean;
  public revisionOrigin: string; // revision code / documentNumber
  public revisionParent: string; // revision code / documentNumber
  public revisionNumber: string;
  public revisionCount: string;
  public tbCode: string;
  public vatGlobal: number;
  public currency: string;
  public contractCondition: string;
  public warranty: string;
  public validity: string;
  public packingConditions: PackingConditions[]; // string;
  public packingStatus: EPackingStatus;
  public packingImages: FileModel[];
  public freightForwarder: FileModel[];
  public scannedPacking: FileModel[];
  public trackingCode: string; // TODO: remove from PackingConditions
  public useOldPackingConditions: boolean;
  public showPackingPalletsInPdf: boolean;
  public showPackingSummaryInPdf: boolean;
  public grossWeight: string; // TODO: integrate with PackingConditions
  public netWeight: string; // TODO: integrate with PackingConditions
  public packingText: string;  // TODO: integrate with PackingConditions
  public showNetWeight: boolean = true; // TODO: integrate with PackingConditions
  public paymentPlan: PaymentPlan;
  public importerInfo: string;
  public invoiceType: string;
  public generalText: string;
  // TODO: FileModel[doctype][] => change numeric type do prop type ([OF,OR,OC,...])
  public documents: FileModel[] | FileModel[][]; // FileModel[doctype][], type = CompilationTypeData.id
  public documentsInternal: FileModel[];
  public commercialTerms: string;
  public transferOfOwnership: string;
  public visibleFields: string[];
  public invoiceDownloaded: boolean = false;
  public invoicePayment: number;
  public fiscalYear: number;
  public differentInvoiceAddress: boolean = false;
  public invoicePercent: number = 100;
  public discount: number = 0;
  public supplierReference: string;
  public relatedDocuments: string;
  public changeProtocol: string; // TODO: unused?
  public nameOverride: string;
  public documentFooter: DocumentFooter;
  public pfspcCode: string; // NRKA Order: associated NRKG PFSPC
  public pfspcDoc: CompilationModel; // not in DB
  public ocCode: string; // NRKG Order Confirmation: associated NRKA Order Confirmation
  public ocDoc: CompilationModel; // not in DB
  public nrkgIn: string[]; 

  public transactionReceipt: InvoiceUpload;
  public proveOfDelivery: InvoiceUpload;
  public testCertificate: InvoiceUpload;
  public testCertificates: FileModel[]; // synced with InvoiceUpload on invoice finalization
  public barcodes: string[];

  public directDelivery: boolean;
  public pfspoWithShipment: boolean;
  public fromValidity: Date; // valid from in FCOR
  public offerValidity: Date; // offer validity in OF; valid to in FCOR
  public latestDeliveryDate: Date; // latestPODate
  public requestedExwDate: Date;
  public exwJapanDate: Date;
  public transferTime1: number;
  public arrivingVieDate: Date;
  public vieDispatchDate: Date;
  public dispatchDateUnconfirmed: boolean;
  public expectedNextInvoiceDate: Date;
  public nextInvoiceDateAttached: boolean;
  public transferTime2: number;
  public etaCustomerDate: Date;
  public customerRequestedDeliveryDate: Date;
  public customerAcceptedDeliveryDate: Date;
  public actualDeliveryDate: Date;
  public readyForPickupDate: Date;
  public firstDate: FirstDate;

  public shipmentLineItems: ShipmentLineItem[];
  public hidePricing: boolean;
  public transportRun: TransportRun;

  public attachToOC: boolean;

  public boxLabelConfig: BoxLabelConfig;

  public spoPayment: SpoPayment[];

  public internalUpdates: string;

  public tmp?: any = {} // temporary data not stored in database TODO: put all cross data into tmp
}
