import { BaseProductModel } from './baseProduct';
import { CoreModel } from './core';
import { BusinessField } from '../business';
import { FileModel, UserModel } from '..';
import { ChangelogEntry } from '../../pages/erp/shared/changelog/changelog.component';

export enum EProductType {
  Energy,
  Electro,
}

// TODO: v2 don't start with 0!!!!
export enum EBoxLabelPriority {
  Standard5Deg = 0,
  ExpiryColumn5Deg,
  ExpiryCalculation5Deg,
  Standard10Deg,
  ExpiryColumn10Deg,
  ExpiryCalculation10Deg,
  Standard20Deg,
  ExpiryColumn20Deg,
  ExpiryCalculation20Deg,
  ExpiryFromDispatch5Deg,
  ExpiryFromDispatch10Deg,
  ExpiryFromDispatch20Deg,
}

export class NameTable {
  id: string;
  nrkName: string;
  baseCode: string;
  bpName: string;
  trial: string;
  nrkCode: string;
  customerName: string;
  customerCode: string;
  core: string;
  calcName: string;
  calcCode: string;
  code: string;
  business: string[];
  allBusinesses: boolean;
}

export class FpEnergy {
  core: CoreModel;
  width: number;
  rollLength: number;
  // TODO: Detailed Product & Package Definition + Attachment
}

export class FpContent {
  energy: FpEnergy;
  electro: string;
}

export class FinishedProductStatus {
  private static data = [
    { id: 1, name: 'Sample' },
    { id: 2, name: 'Active' },
    { id: 3, name: 'Inactive' },
    { id: 4, name: 'Template' },
  ]
  static getOptions = () => FinishedProductStatus.data;
  static get = (idOrName: number | string) => typeof idOrName === 'number' ? this.data.find(f => f.id === idOrName) : this.data.find(f => f.name === idOrName);
}
export enum EFinishedProductStatus {
  sample = 1,
  active = 2,
  inactive = 3,
  template = 4,
}

export class FinishedProductModel {
  public _id?: string;

  product: BaseProductModel;
  // TODO: remove type (determined by baseproduct type == insulation[6])
  type: EProductType;
  status: number;
  customers: BusinessField[];
  allCustomers: boolean;
  suppliers: BusinessField[];
  allSuppliers: boolean;
  calcCode: string;
  calcName: string;
  nameOverride: string;
  code: string;
  hs: string;             // hs code
  content: FpContent;
  externalName: string;
  externalCode: string;
  obic: string;
  remark: string;
  publicRemark: string;
  version: number;
  disabled: boolean = false;
  files: FileModel[];
  customerProductSpecification: FileModel[];
  customerIdentificator: FileModel;
  showMissingCpsWarning: boolean = true;
  draft: boolean;
  changelog: string | ChangelogEntry[];
  editCount: number = 0;
  owner: UserModel;
  machineryWeight: number;
  micaInOut: string;
  finishedRollWindingStrength: string;
  jointTapeType: string;
  jointTapeWidth: string;
  jointMethod: string;
  jointLocation: string;
  jointsWithinQuantification: string;
  jointOtherRequirements: string;
  packing: string;
  coreLabeling: string
  insidePackingLabeling: string;
  outsideCartonBoxLabeling: string;
  deliveryCollieLabeling: string;
  requiredTestParameters: string;
  specifiedShelfLifeCondition: string;
  packingSpec: string[];
  cartonSize: string[];
  piecesPerCarton: number[];
  boxLabelPriority: EBoxLabelPriority;
}
