import {BusinessUnitModel} from './businessUnit';

export class MaterialModel {
  public _id: string;
  public businessUnit: BusinessUnitModel | string;
  public name: string;
  public description: string;
  // public buAccounting: number;
  public code: string;
}
