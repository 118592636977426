import { DatePipe } from '@angular/common';
import { Injectable, NgIterable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { lastValueFrom, Subject } from 'rxjs';
import { BusinessModel, BusinessUnitModel, ContactModel, EContactFieldType, EContactType, MaterialModel, UserModel } from '../../@models';
import { BaseProductModel } from '../../@models/erp/baseProduct';
import { CompilationModel, IPalletRef } from '../../@models/erp/compilation';
import { CompilationDataModel, CompilationTypeData, DocConfig, IssuerData } from '../../@models/erp/compilationData';
import { CoreModel } from '../../@models/erp/core';
import { EProductType, FinishedProductModel, NameTable } from '../../@models/erp/finishedProduct';
import { RegionModel } from '../../@models/region';
import { distinctFromObjectArray, isEmpty, latestVersion, mongoId } from '../common/global';
import { ApiService } from './api.service';
import { TreeService } from './tree.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { Component } from '@angular/core';
import { SpoBoxModel } from '../../@models/erp/spoBox';
import { PackingConditionsComponent } from '../../pages/erp/document/packing-conditions/packing-conditions.component';
import { EWarehousingEntryType, WarehousingModel } from '../../@models/warehousing';
import * as moment from 'moment';
import { ContactUtil } from '../../pages/erp/shared/doc-form-address-selector/doc-form-address-selector.component';

export interface IDropdownOptions {
  id: string;
  name: string;
}

export interface IPositionMap {
  [code: string]: { [internalPos: number] : /* pos */ number }
}

@Injectable()
export class SharedDataService  {

  public totalNet: number;
  public boxesAreLoading: boolean[] = [];
  public reportIsLoading = false;

  // businesses
  private _businesses: BusinessModel[];
  private _businessesWithDisabled: BusinessModel[];
  private _businessOptions: IDropdownOptions[];
  public get businessesWithDisabled(): BusinessModel[] {
    return this._businessesWithDisabled;
  }
  public get businessOptions(): IDropdownOptions[] {  // dropdown / select options
    return this._businessOptions;
  }
  public get businesses(): BusinessModel[] {
    return this._businesses;
  }
  public business = {
    micamation: null,
    nrt: null,
    nrvi: null,
    bartech: null,
    nrka: null,
    nrkg: null,
    nrkj: null,
    nrkv: null,
    ceva: null,
    dhl: null,
    jerich: null,
  }
  loadBusinesses = async () => this._businesses || await this.reloadBusinesses();

  // business units
  private _businessUnits: BusinessUnitModel[];
  public businessUnitOptions: IDropdownOptions[] = [];
  public get businessUnits(): BusinessUnitModel[] {
    return this._businessUnits;
  }
  loadBusinessUnits = async () => this._businessUnits || await this.reloadBusinessUnits();

  // materials
  private _materials: MaterialModel[];
  public materialOptions: IDropdownOptions[] = [];
  public get materials(): MaterialModel[] {
    return this._materials;
  }
  loadMaterials = async () => this._materials || await this.reloadMaterials();

  // users
  private _users: UserModel[];
  public userOptions: IDropdownOptions[] = [];
  public get users(): UserModel[] {
    return this._users;
  }
  loadUsers = async () => this._users || await this.reloadUsers();
  userName = (user: UserModel) => this._users?.find(f => mongoId(f) === mongoId(user))?.name || '';
  userShort = (user: UserModel) => this._users?.find(f => mongoId(f) === mongoId(user))?.short || '';

  // contacts
  private _contacts: ContactModel[];
  public contactOptions: IDropdownOptions[] = [];
  public get contacts(): ContactModel[] {
    return this._contacts;
  }
  loadContacts = async () => this._contacts || await this.reloadContacts();

  // cores
  private _cores: CoreModel[];
  public coreOptions: IDropdownOptions[] = [];
  public get cores(): CoreModel[] {
    return this._cores;
  }
  loadCores = async () => this._cores || await this.reloadCores();

  // base products + finished products
  private _products: any;
  public baseProductOptions: IDropdownOptions[] = [];
  public get baseProducts(): BaseProductModel[] {
    return this._products?.baseProducts;
  }
  public finishedProductOptions: IDropdownOptions[] = [];
  public get finishedProducts(): FinishedProductModel[] {
    return this._products?.finishedProducts;
  }
  public finishedProductNames: NameTable[] = [];
  loadProducts = async () => this._products || await this.reloadProducts();

  // boxes
  private _spoBoxes: SpoBoxModel[];
  public get spoBoxes(): SpoBoxModel[] {
    return  this._spoBoxes;
  }
  loadSpoBoxes = async () => this._spoBoxes || await this.reloadSpoBoxes();

  private _palletReferences: IPalletRef[];
  public get palletRefs(): IPalletRef[] {
    return this._palletReferences;
  }
  loadPalletRefs = async() => this._palletReferences || await this.reloadPalletReferences();

  // position map
  private _positionMap: IPositionMap;
  public get positionMap(): IPositionMap {
    return  this._positionMap;
  }
  public getPos(code: string, internalPos: number) {
    const doc = this.positionMap[code];
    if (doc) {
      const li = doc[+internalPos];
      return li === undefined ? '' : +li + 1
    }
    return ''
  }
  loadPositionMap = async () => this._positionMap || await this.reloadPositionMap();

  // compilation data
  private _compilationData: CompilationDataModel;
  public get compilationData(): CompilationDataModel {
    return this._compilationData;
  }
  loadCompilationData = async () => this._compilationData || await this.reloadCompilationData();
  private _paymentTextOptions: IDropdownOptions[];
  public get paymentTextOptions(): IDropdownOptions[] {
    return this._paymentTextOptions;
  }
  private _termsOfPaymentOptions: IDropdownOptions[];
  public get termsOfPaymentOptions(): IDropdownOptions[] {
    return this._termsOfPaymentOptions;
  }
  private _paymentPlanTextOptions: IDropdownOptions[];
  public get paymentPlanTextOptions(): IDropdownOptions[] {
    return this._paymentPlanTextOptions;
  }
  private _palletTypeOptions: IDropdownOptions[];
  public get palletTypeOptions(): IDropdownOptions[] {
    return this._palletTypeOptions;
  }
  private _cartonBoxOptions: IDropdownOptions[];
  public get cartonBoxOptions(): IDropdownOptions[] {
    return this._cartonBoxOptions;
  }
  private _packingOptions: IDropdownOptions[];
  public get packingOptions(): IDropdownOptions[] {
    return this._packingOptions;
  }

  // compilation documents basic data
  private _compilationItems: CompilationModel[] = [];
  public get compilationItems(): CompilationModel[] {
    return this._compilationItems;
  }
  public set compilationItems(c : CompilationModel[]) {
    this._compilationItems = c;
  }

  // preloadCompilations = async () => this._compilationItems || await this.reloadCompilationItems(true);
  // loadCompilations = async () => this._compilationItems || await this.reloadCompilationItems();
  private _latestVersionItems: CompilationModel[];
  public get latestVersionItems(): CompilationModel[] {
    return this._latestVersionItems;
  }
  public set latestVersionItems(infos: CompilationModel[]) {
    this._latestVersionItems = infos;
  }

  private _regions: RegionModel[];
  public get regions(): RegionModel[] {
    return this._regions;
  }
  loadRegions = async () => this._regions || await this.reloadRegions();

  // warehousing log / messages
  private _warehousingData: WarehousingModel[];
  private _warehousingLog: WarehousingModel[];
  private _warehousingMessages: WarehousingModel[];
  public get warehousingLog(): WarehousingModel[] {
    return this._warehousingLog;
  }
  public get warehousingMessages(): WarehousingModel[] {
    return this._warehousingMessages;
  }
  loadWarehousing = async () => this._warehousingData || await this.reloadWarehousing();

  public $expiryTime = new Subject<string>();

  public tcSpoMap: NgIterable<any>;

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  public packingComponent: PackingConditionsComponent;

  public visibleMap: any = {}

  public readOnlyMap: any = {}

  constructor(
    private api: ApiService,
    private datePipe: DatePipe,
    private domSanitizer: DomSanitizer,
    private clipboard: Clipboard,
  ) {}


  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  

  async reloadBusinesses() {
    const start = new Date().getTime();
    this._businesses = []; // avoid reloading with this.loadBusinesses()
    return new Promise<BusinessModel[]>( resolve => {
      const subscr = this.api.reloadBusiness().subscribe(b => {
        this._businessesWithDisabled = b;
        this._businesses = b.filter(f => f.active);
        this._businessOptions = b ? this._businesses.map(el => ({ id: el._id, name: el.name })) : [];

        const a = b.filter(f => f.active); // active business only for this.business

        this.business.micamation = a.find(f => /^Micamation$/i.test(f.name));
        this.business.nrt = a.find(f => /^NRT$/i.test(f.name));
        this.business.nrvi = a.find(f => /^NRVI$/i.test(f.name));
        this.business.bartech = a.find(f => /^NRBM$/i.test(f.name));
        this.business.nrka = a.find(f => /^NRKA$/i.test(f.name));
        this.business.nrkg = a.find(f => /^NRKG$/i.test(f.name));
        this.business.nrkj = a.find(f => /^NRKJ$/i.test(f.name));
        this.business.nrkv = a.find(f => /^NRKV$/i.test(f.name));
        this.business.ceva = a.find(f => /^CEVA.*Airport/i.test(f.name));
        this.business.dhl = a.find(f => /^DHL /.test(f.name));
        this.business.jerich = a.find(f => /^Jerich Transport/i.test(f.name));

        console.log('reload', this.business.jerich, this.business)
    
        subscr.unsubscribe();
        console.info('@shared-data: load businesses took: ', (new Date().getTime() - start) + 'ms');
        resolve(this._businesses);
      })
    })
  }

  async reloadBusinessUnits() {
    const start = new Date().getTime();
    this._businessUnits = []; // avoid reloading cf. reloadBusinesses()
    return new Promise<BusinessUnitModel[]>(resolve => {
      const subscr = this.api.reloadBusinessUnits().subscribe(_bu => {
        this._businessUnits = _bu;
        this.businessUnitOptions = this._businessUnits.map(el => ({ id: el._id, name: el.name }))
        subscr.unsubscribe();
        console.info('@shared-data: load business units took: ', (new Date().getTime() - start) + 'ms');
        resolve(this._businessUnits);
      });
    })
  }

  async reloadMaterials() {
    const start = new Date().getTime();
    this._materials = [];
    return new Promise<MaterialModel[]>( resolve => {
      const subscr = this.api.reloadMaterials().subscribe(mat => {
        this._materials = mat;
        this.materialOptions = this._materials.map(el => ({ id: el._id, name: el.name }))
        subscr.unsubscribe();
        console.info('@shared-data: load materials took: ', (new Date().getTime() - start) + 'ms');
        resolve(this._materials)
      })
    })
  }

  async reloadUsers() {
    const start = new Date().getTime();
    this._users = []; // avoid reloading cf. reloadBusinesses()
    this._users = await this.api.reloadUsers();
    this.userOptions = this._users.map(u => ({ id: u._id, name: u.name })).sort((a, b) => a.name.localeCompare(b.name));
    console.info('@shared-data: load users took: ', (new Date().getTime() - start) + 'ms');
    return this._users;
  }

  async reloadContacts() {
    const start = new Date().getTime();
    this._contacts = []; // avoid reloading cf. reloadBusinesses()
    return new Promise<ContactModel[]>(resolve => {
      const subscr = this.api.reloadContacts().subscribe(c => {
        this._contacts = c;
        this.contactOptions = this._contacts.map(el => ({ id: el._id, name: el.title }))
        subscr.unsubscribe();
        console.info('@shared-data: load contacts took: ', (new Date().getTime() - start) + 'ms');
        resolve(this._contacts);
      })
    })
  }

  async reloadCores() {
    const start = new Date().getTime();
    this._cores = []; // avoid reloading cf. reloadBusinesses()
    this._cores = await this.api.loadCores();
    this.coreOptions = this._cores.sort((a,b) => a.name.localeCompare(b.name)).map(el => ({ id: el._id, name: el.name }))
    console.info('@shared-data: load cores took: ', (new Date().getTime() - start) + 'ms');
    return this._cores;
  }

  async reloadProducts() {
    const start = new Date().getTime();

    const baseProducts = latestVersion(await this.api.loadBaseProducts());
    this.baseProductOptions = baseProducts
      .sort((a, b) => a.version < b.version ? 1 : -1)
      .map(el => ({ id: el._id, name: `${el.name} [${el.trial}]` }))

    const finishedProducts = latestVersion(await this.api.loadFinishedProducts());
    await this.loadBusinessUnits();
    await this.loadMaterials();
    await this.loadCores();
    finishedProducts?.forEach(fp => {
      if (fp?.product) {
        fp.product = baseProducts.find(bp => mongoId(bp) === mongoId(fp.product));
        if (fp.product) {
          fp.product.businessUnit = this.businessUnits.find(bu => bu._id === mongoId(fp.product?.businessUnit));
          fp.product.material = this.materials.find(m => m._id === mongoId(fp.product.material));
        }
      }
      let customerName = '', core = '';
      if (fp.type === EProductType.Electro) {
        customerName = fp.content.electro;
      } else {
        const name = fp.product ? fp.product['name'] + '; ' : '';
        if (fp.content.energy?.core) {
          fp.content.energy.core = this.cores.find(c => c._id === mongoId(fp.content.energy.core));
          core = this.getFinishedProductDimensions(fp) || '';
        }
        customerName = name + core;
      }
      const customers = Array.isArray(fp.customers) ? fp.customers : [fp.customers];
      const suppliers = Array.isArray(fp.suppliers) ? fp.suppliers : [fp.suppliers];
      const business = [...customers, ...suppliers].map(m => m && mongoId(m.id));

      this.finishedProductNames.push({
        id: fp._id,
        nrkName: fp.nameOverride != null && fp.nameOverride.length > 0 ? fp.nameOverride : fp.product?.name,
        baseCode: fp.product?.code,
        bpName: fp.product?.name,
        trial: fp.product?.trial,
        nrkCode: fp.product?.businessUnit?.code + '-' + fp.product?.material?.code + '-' + fp.code,
        customerName: (fp.externalName ? fp.externalName : customerName)?.replace(/\;\s*$/, ''),
        customerCode: fp.externalCode,
        core: core,
        calcCode: fp.calcCode,
        calcName: fp.calcName,
        code: fp.code,
        business: business,
        allBusinesses: fp.allCustomers || fp.allSuppliers,
      });
    })
    this.finishedProductOptions = finishedProducts.map(el => ({ id: el._id, name: el.calcName }))

    console.info('@shared-data: load products took: ', (new Date().getTime() - start) + 'ms');
    this._products = { baseProducts, finishedProducts }
    return this._products;
  }
  getFinishedProductDimensions(fp: FinishedProductModel): string {
    if (!fp._id) fp = this.finishedProducts.find(f => mongoId(f) === mongoId(fp));
    return fp.content.energy?.core?.name ? fp.content.energy.width + '-' + fp.content.energy.rollLength + '-' + fp.content.energy.core.name : null;
  }
  getFinishedProductString(fp: FinishedProductModel): string {
    if (!fp._id) fp = this.finishedProducts.find(f => mongoId(f) === mongoId(fp));
    return `${fp.calcName};`;
  }

  async reloadSpoBoxes() {
    const start = new Date().getTime();
    this._spoBoxes = [];
    this.boxesAreLoading.push(true);
    this._spoBoxes = await this.api.loadSpoBoxes();
    this.boxesAreLoading.pop();
    console.info('@shared-data: load spo boxes took: ', (new Date().getTime() - start) + 'ms');
  }

  async reloadPalletReferences() {
    const start = new Date().getTime();
    this._palletReferences = [];
    this._palletReferences = await this.api.getPalletReferences();
    console.info('@shared-data: load pallet references took: ', (new Date().getTime() - start) + 'ms');
  }

  async reloadPositionMap() {
    const start = new Date().getTime();
    this._positionMap = await this.api.getPositionMap();
    console.log('position map: ', this.positionMap );
    console.info('@shared-data: load position map took: ', (new Date().getTime() - start) + 'ms');
  }

  async reloadCompilationData() {
    const start = new Date().getTime();
    this._compilationData = new CompilationDataModel(); // avoid reloading cf. reloadBusinesses()
    this._compilationData = await this.api.getCompilationData();

    this._paymentTextOptions = this.compilationData.paymentStore?.find(f => f.id === 'paymentText')?.data?.map(m => ({ id: m.text, name: m.text }));
    this._termsOfPaymentOptions = this.compilationData.paymentStore?.find(f => f.id === 'termsOfPayment')?.data?.map(m => ({ id: m.text, name: m.text }));
    this._paymentPlanTextOptions = this.compilationData.paymentStore?.find(f => f.id === 'paymentPlanText')?.data?.map(m => ({ id: m.text, name: m.text }));
    this._palletTypeOptions = this.compilationData.packingStore.find(f => f.id === 'palletDetails')?.data.map(m => ({ id: m.id, name: m.name }));
    this._cartonBoxOptions = this.compilationData.packingStore.find(f => f.id === 'boxDetails')?.data.map(m => ({ id: m.id, name: m.name }));
    this._packingOptions = this.compilationData.packingStore.find(f => f.id === 'finalPacking')?.data.filter(f => !f.disabled).map(m => ({ id: m.id, name: `${m.id} ${m.name}` }));
    console.log('final packing', this.compilationData.packingStore.find(f => f.id === 'finalPacking')?.data, this.compilationData.packingStore.find(f => f.id === 'finalPacking')?.data.filter(f => !f.disabled))

    console.info('@shared-data: load compilationData took: ', (new Date().getTime() - start) + 'ms');
    return this._compilationData;
  }

  async reloadCompilationItems(from: number, len: number) {
    if (from === 0) this.reloadPositionMap();
    const start = new Date().getTime();
    const slice = await this.api.getAllVersionData(true, false, null, null, from, len)
    // console.log('len pre', this._compilationItems?.length, this._latestVersionItems?.length);
    this._compilationItems.push(...(slice as CompilationModel[]));
    this._latestVersionItems = distinctFromObjectArray(this._compilationItems, 'code');
    console.info(`@shared-data: load compilation infos; slice (${from}-${from+len}) took: ${new Date().getTime() - start} ms`);
    // console.log('len post', this._compilationItems?.length, this._latestVersionItems?.length, (slice as CompilationModel[]))
    return slice;
  }

  // async reloadCompilationItems() {
  //   const start = new Date().getTime();
  //   this._compilationItems = [];
  //   this._compilationItems = await this.api.getAllVersionData(preload) as CompilationModel[];
  //   console.info('@shared-data: load compilation infos; preload:', preload, new Date().getTime() - start);
  //   return this._compilationItems;
  // }

  // async reloadCompilationInfos(preload = false, abstractItem: AbstractItem<CompilationModel>) {
  //   console.log('@shared-data: load compilation infos');
  //   abstractItem.items = await this.api.getAllVersionData(preload) as CompilationModel[];
  //   this._compilationInfos = abstractItem.getCurrentItems(abstractItem.items, true)
  //     .sort((a, b) => a['updatedAt'] < b['updatedAt'] ? 1 : -1);
  //   return this._compilationInfos;
  // }

  async reloadRegions() {
    const start = new Date().getTime();
    return new Promise<RegionModel[]>(resolve => {
      const subscr = this.api.reloadRegions().subscribe(regions => {
        this._regions = regions;
        subscr.unsubscribe();
        console.info('@shared-data: load regions took: ', (new Date().getTime() - start) + 'ms');
        resolve(this._regions);
      })
    })
  }

  async reloadWarehousing(forced = false) {
    const start = new Date().getTime();
    let itemsLoaded = 0
    if (!this._warehousingData?.length || forced) {
      this._warehousingData = await this.api.loadWarehousingEntries();
      // console.log('warehousing data initial load: ', this._warehousingData.length);
      itemsLoaded = this._warehousingData.length;
    } else {
      const latestDate = moment.max(this._warehousingData.map(m => moment(m.timestamp))).toDate();
      const newEntries = await this.api.loadWarehousingEntries({ timestamp: { $gt: latestDate }});
      // console.log('reload warehousing: entries: ',this._warehousingData.length, ', new entries: ', newEntries.length);
      if (newEntries?.length) this._warehousingData.push(...newEntries);
      itemsLoaded = newEntries.length;
    }
    this._warehousingMessages = this._warehousingData.filter(f => f.entryType === EWarehousingEntryType.message).reverse();
    this._warehousingLog = this._warehousingData.filter(f => f.entryType === EWarehousingEntryType.documentLog).reverse();
    console.info(`@shared-data: load warehousing data (${itemsLoaded} item(s)) took: `, (new Date().getTime() - start) + 'ms');
  }

  async reloadTcSpoMap(compilation: CompilationModel) {
    const map = [];
    for (const pc of compilation?.packingConditions) {
      console.log('pc.items', pc.items);
      if (!Array.isArray(pc.items)) continue;
      for (const item of pc.items) {
        for (const tc of item.tcNr.replace(/\s/g,'').split(';').filter(notNull => notNull)) {
          if (!map.find(f => f.tc === tc)) {
            const rec = await this.api.getSpoOfTC(tc);
            map.push({
              tc: tc,
              spo: rec.spo?.join(', '),
            })
          }
        }
      }
    }
    console.log('getTcSpoMap', compilation.code, map);
    this.tcSpoMap = map;
  }

  // ========================================================================================================================

  getOptionName(opts: IDropdownOptions[], id: any) {
    return opts?.find(f => f?.id == id)?.name || '';
  }

  setCSIdentifier(doc: CompilationModel) {
    if (!doc || doc.type !== CompilationTypeData.get('CS').id) return;
    const exwDate = doc.exwJapanDate ? this.datePipe.transform(doc.exwJapanDate, 'yyMMdd') : '___';
    const manufacturer = (this.businesses.find(b => mongoId(b) === mongoId(doc.manufacturerRecipient)) || {} as any).name || '___';
    const deliverer = (this.businesses.find(b => mongoId(b) === mongoId(doc.deliveryRecipient)) || {} as any).name || '___';
    const carrierMethod = doc.meansOfTransport || '___';

    doc.internal = `${exwDate}-${manufacturer}-${deliverer}-${carrierMethod}`;
  }

  getFileDescriptions(id: string) {
    const texts = this._compilationData && this._compilationData.fileDescriptions.find(fd => fd.id === id);
    return texts && texts.data;
  }

  setDocConfigMaps(currentCompilationType: number) {
    for (const section of DocConfig.getArray()) {
      this.visibleMap[section.key] = DocConfig.checkVisible(section.key, currentCompilationType);
      this.readOnlyMap[section.key] = DocConfig.checkReadOnly(section.key, currentCompilationType);
    }
  }

  getDeliveryAndTerms(businessModel: BusinessModel, contact: ContactModel = null) {
    const business = this._businesses.find(f => mongoId(f) === mongoId(businessModel));
    const delivery = business?.contacts?.find(c => c.type == EContactType.Delivery);
    const general = business?.contacts?.find(c => c.type == EContactType.General);
    const incoterms = (contact || delivery || general)?.fields?.find(f => +f.type === EContactFieldType.Incoterms);
    const place = (contact || delivery || general)?.fields?.find(f => +f.type === EContactFieldType.IncotermsPlace);
    const country = (contact || delivery || general)?.fields?.find(f => +f.type === EContactFieldType.IncotermsCountry);
    const paymentText = (contact || general)?.fields?.find(f => +f.type === EContactFieldType.PaymentText);
    const terms = (contact || general)?.fields?.find(f => +f.type === EContactFieldType.PaymentConditions);
    const vat = (contact || general)?.fields?.find(f => +f.type === EContactFieldType.VAT_Number);
    return { incoterms, place, country, paymentText, terms, vat }
  }

  getBusinessManagerUser(business: BusinessModel) {
    const _business = this._businesses.find(f => mongoId(f) === mongoId(business));
    const user = this._users.find(u => mongoId(u.contact) === mongoId(_business?.manager))
    return user;
  }

  getBusinessName(business: BusinessModel) {
    return this.businesses.find(f => mongoId(f) === mongoId(business))?.name || '';
  }

  getObicString(compilation: CompilationModel, tree: TreeService) {
    if (['OBIC'].includes(CompilationTypeData.get(compilation.type)?.code) && compilation.nameOverride) return;
    if (['OBIC', 'PFSPO', 'SPO'].includes(CompilationTypeData.get(compilation.type)?.code)) {
      if (tree.linkedObic) compilation = tree.linkedObic;
      return compilation.lineItems.map(m => m.tbCode)?.filter(notNull => notNull && notNull.length)?.join(', ');
    }
  }

  sanitizeHtml(html: string) {
    // return html && this.domSanitizer.bypassSecurityTrustHtml(html);
    return this.domSanitizer.sanitize(SecurityContext.HTML,this.domSanitizer.bypassSecurityTrustHtml(html));

  }

  copyToClipboard(toCopy: string): void {
    // const createCopy = (e: ClipboardEvent) => {
    //   e.clipboardData.setData('text/plain', toCopy);
    //   e.preventDefault();
    // };
    // document.addEventListener('copy', createCopy );
    // document.execCommand('copy');
    // document.removeEventListener('copy', createCopy );
    this.clipboard.copy(toCopy);
  }

  copyHtmlToClipboard(html: string): void {

    var container = document.createElement('div')
    container.innerHTML = html
  
    container.style.position = 'fixed'
    container.style.pointerEvents = 'none'
    container.style.opacity = '0'
  
    // Detect all style sheets of the page
    // var activeSheets = Array.prototype.slice.call(document.styleSheets)
    //   .filter(function (sheet) {
    //     return !sheet.disabled
    //   })
  
    document.body.appendChild(container)
    window.getSelection().removeAllRanges()
  
    var range = document.createRange()
    range.selectNode(container)
    window.getSelection().addRange(range)
  
    document.execCommand('copy')
    // for (var i = 0; i < activeSheets.length; i++) activeSheets[i].disabled = true
    // document.execCommand('copy')
    // for (var i = 0; i < activeSheets.length; i++) activeSheets[i].disabled = false
    document.body.removeChild(container)
  }

  getPackingConfig(id: string = null) {
    const palletDetails = this._compilationData.packingStore.find(f => f.id === 'palletDetails')?.data;
    const boxDetails = this._compilationData.packingStore.find(f => f.id === 'boxDetails')?.data;
    const finalPacking = this._compilationData.packingStore.find(f => f.id === 'finalPacking')?.data;

    // console.log('getPackingConfig', id)
    if (!id) return ({ palletDetails, boxDetails, finalPacking }) as any

    for (const el of [...palletDetails, ...boxDetails, ...finalPacking]) {
      // if (el === id) console.log('getPackingConfig', el.id === id, el)
      if (el.id === id) return el;
    }

  }

  getIssuerFromCode(code: string, asNumber = false) {
    const match = code.match(/^.*(NRK.).*$/);
    return match && (asNumber ? IssuerData.get(match[1]?.toLocaleLowerCase()).id : match[1]?.toLocaleLowerCase())
  }

  /**
   * 1) Das Dispatch date ist die maßgebliche Periode. 
   * 2) wenn Issue date > dispatch date UND das issue date fällt in eine anders Periode, gilt die andere Periode
   * fiscal period eg.: 2024-10-01 to 2025-09-30
   * @param doc 
   * @returns 
   */
  fiscalYearUpdate(doc: CompilationModel) {

    function getPeriod(date: Date) {
      const year = date.getFullYear();
      const startOfPeriod = new Date(year, 9, 1); // October 1st
      const endOfPeriod = new Date(year + 1, 8, 30); // September 30th of the next year
  
      if (date >= startOfPeriod && date <= endOfPeriod) {
          return year;
      } else {
          return year - 1;
      }
    }

    if (!doc.vieDispatchDate) return;

    const issueDateObj = new Date(doc.issueDate);
    const dispatchDateObj = new Date(doc.vieDispatchDate);

    const dispatchPeriod = getPeriod(dispatchDateObj);
    const issuePeriod = getPeriod(issueDateObj);

    if (issueDateObj > dispatchDateObj && dispatchPeriod !== issuePeriod) {
      // console.log(`-----> fiscal year update ${doc.code}: ${doc.issueDate} > ${doc.vieDispatchDate} = ${issuePeriod}`);
      doc.fiscalYear = issuePeriod;
    } else {
      // console.log(`..................${doc.code}: ${doc.vieDispatchDate} = ${dispatchPeriod}`)
      doc.fiscalYear = dispatchPeriod;
    }

  }

  paymentPlanHasInvoices(doc: CompilationModel) {
    return !isEmpty(doc.paymentPlan?.items?.find(f => f.invoices?.length > 0));
  }


  getCustomerInvoiceAddress(business: BusinessModel) {
    return (new ContactUtil(this)).getAddressFromBusiness(
      mongoId(business), 
      DocConfig.get('invoiceSendToAddress').fields, 
      EContactType.Invoice,
      false
    )
  }

}
