import { DatePipe } from '@angular/common';
import { EventEmitter, Injectable, Output, Directive } from '@angular/core';
import { CompilationModel, DeductibleItem, EPackingStatus, PackingConditions, TransportRun } from '../../@models/erp/compilation';
import { CompilationTypeData, DocConfig, DocumentStatusData, InvoicePaymentData } from '../../@models/erp/compilationData';
import { distinctFromObjectArray, getDocumentNumber, mongoId } from '../common/global';
import { ApiService } from './api.service';
import { SharedDataService } from './shared-data.service';
import * as moment from 'moment';
import { ChangelogUtil } from '../../pages/erp/utilities/utilities.component';
import { Changelog } from '../../pages/erp/shared/changelog/changelog.component';
import { AuthGuardService } from './auth-guard.service';
import { LineItemModel } from '../../@models/erp/lineItem';

export interface IDocumentTree {
  associated: any; // TODO: remove associated
  code: string;
  children: IDocumentTree[];
  doc: CompilationModel;
}

@Directive()
@Injectable()
export class TreeService  {

  @Output() documentTreeStartLoadingEvent = new EventEmitter<IDocumentTree>();
  @Output() documentTreeLoadedEvent = new EventEmitter<IDocumentTree>();

  private root: IDocumentTree = null;
  public get rootNode(): IDocumentTree {
    return this.root
  }

  private _withCancelledRoot: IDocumentTree = null;
  public get withCancelledRoot(): IDocumentTree {
    return this._withCancelledRoot;
  }

  private node: IDocumentTree = null;
  public get docNode(): IDocumentTree {
    return this.node;
  }

  private parent: IDocumentTree = null;
  public get parentNode(): IDocumentTree {
    return this.parent;
  }

  private order: CompilationModel = null;
  public get linkedOR(): CompilationModel {
    return this.order;
  }

  private offer: CompilationModel = null;
  public get linkedOF(): CompilationModel {
    return this.offer;
  }

  private frameContract: CompilationModel = null;
  public get linkedFC(): CompilationModel {
    return this.frameContract;
  }

  private orderConfirmation: CompilationModel = null;
  public get linkedOC(): CompilationModel {
    return this.orderConfirmation;
  }
  public get linkedOCTreeDocs(): CompilationModel[] {
    const docs = this.linkedOC ? this.getFlatByDocType('OC', this.linkedOC) : [];
    // console.log('linkedOCTreeDocs', docs);
    return docs;
  }

  private deliveryNote: CompilationModel = null;
  public get linkedDN(): CompilationModel {
    return this.deliveryNote;
  }

  private invoice: CompilationModel = null;
  public get linkedIN(): CompilationModel {
    return this.invoice;
  }

  private associatedPfspos: CompilationModel[] = null;
  private csSpoCodes: string[];
  public get linkedPfspos(): CompilationModel[] {
    return this.associatedPfspos;
  }
  public set linkedPfspos(spos: CompilationModel[]) {
    this.associatedPfspos = spos;
  }
  public get linkedPfspoCodes(): string[] {
    return this.associatedPfspos?.map(m => m.code);
  }
  public get linkedCsSpoCodes(): string[] {
    return this.csSpoCodes?.length 
      ? this.csSpoCodes 
      : this.combinedShipment
        ? [this.combinedShipment.code] : [];
  }

  private obic: CompilationModel = null;
  public get linkedObic(): CompilationModel {
    return this.obic;
  }

  private combinedShipment: CompilationModel = null;
  public get linkedCS(): CompilationModel {
    return this.combinedShipment;
  }
  public set linkedCS(cs: CompilationModel) {
    this.combinedShipment = cs;
    if (this.node) {
      if (!this.node.doc.transportRun) this.node.doc.transportRun = new TransportRun();
      this.node.doc.transportRun.csDoc = cs;
    }
  }

  private combinedShipments: CompilationModel[] = null;
  public get linkedCSs(): CompilationModel[] {
    return this.combinedShipments;
  }
  public set linkedCSs(css: CompilationModel[]) {
    this.combinedShipments = css;
  }
  public get linkedCSsCodes(): string[] {
    return this.linkedCSs?.map(m => m.code);
  }

  private invoices: CompilationModel[] = null;
  public get invoiceDocs(): CompilationModel[] {
    return this.invoices;
  }

  public get directDelivery(): boolean {
    return this.associatedPfspos && this.associatedPfspos.length && this.associatedPfspos.reduce((a, c) => a && c.directDelivery, true);
  }

  private loading = false;
  public get isLoading(): boolean {
    return this.loading;
  }
  public stopLoading() {
    this.api.cancelGetDocumentTree();
    this.loading = false;
  }

  public paymentPlanInvoiceData = { treeInvoices: [], invoices: [] };

  constructor(private api: ApiService, private data: SharedDataService, private auth: AuthGuardService) {
  }

  public clear() {
    this.root = null;
    this.node = null;
    this.parent = null;
    this.order = null;
    this.offer = null;
    this.frameContract = null;
    this.orderConfirmation = null;
    this.deliveryNote = null;
    this.invoice = null;
    this.associatedPfspos = null;
    this.csSpoCodes = [];
    this.combinedShipment = null;
    this.combinedShipments = null;
  }

  public async loadDocumentTree(doc: CompilationModel) {
    const code = doc.code;
    if (!code) return;

    console.log(`document change: loadDocumentTree begin`, doc.code)

    const b = new Date().getTime();
    this.loading = true;
    this.documentTreeStartLoadingEvent.emit();
    this.clear();
    this._withCancelledRoot = await this.api.getDocumentTree(code);

    this.root = this.filterCancelled(this._withCancelledRoot);
    console.log(`XXXXXXXXXXXXXXXXXXX document tree ${code} loaded XXXXXXXXXXXXXXXXXXX (${new Date().getTime() - b}ms)`)
    console.log('full tree', this._withCancelledRoot);
    console.log('tree', this.root);

    this.node = this.findNode(code) || this.findNode(code, true);
    if (this.node.doc) {
      doc.transportRun = this.node.doc.transportRun;
      doc.lineItems.forEach((el, idx) => {
        el.relatedOCs = this.node.doc.lineItems[idx].relatedOCs
        el.pfspo = this.node.doc.lineItems[idx].pfspo;
        // el.finishedProduct = this.node.doc.lineItems[idx].finishedProduct;
        this.data.loadProducts().then(p => {
          el.finishedProduct = p.finishedProducts.find(f => mongoId(f) === mongoId(this.node.doc.lineItems[idx].finishedProduct));
        })
        // console.log(el.finishedProduct);
      });
      doc.shipmentLineItems = this.node.doc.shipmentLineItems;
      doc.shipmentLineItems?.forEach(li => li.code = li.document.code);
      doc.nrkgIn = this.node.doc.nrkgIn;
    }
    this.node.doc = doc;
    this.populateProps(doc);

    this.assignCompilationModel(this.root);

    this.paymentPlanInvoiceData.treeInvoices = [];
    this.invoices = this.root ? [
      ...this.filterDocumentTree('IN', this.findNode(doc.code)),
      ...this.filterDocumentTree('PFIN', this.findNode(doc.code)),
      ...this.filterDocumentTree('CN', this.findNode(doc.code)),
      ...this.filterDocumentTree('PFCN', this.findNode(doc.code)),
    ] : []
    this.invoices?.forEach(async inv => {
      // const  = inv.lineItems.reduce((a, c: LineItemModel) => a + c.quantity * )
      let totalNet = inv.lineItems.reduce((a, c: LineItemModel) => {
        let net = c.quantity * c.piecePrice;
        if (c.invoicePercent > 0) net = net * c.invoicePercent / 100;
        // net = net + (net * c.VATPercent / 100);
        return a + net;
      }, 0);
      totalNet += inv.deductibleItems.reduce((a, c: DeductibleItem) => a + c.value, 0);
      const data = {
        doc: inv,
        payment: InvoicePaymentData.get(inv.invoicePayment)?.name || '',
        issueDate: (typeof inv.issueDate === 'string' ?  inv.issueDate : inv.issueDate.toISOString())?.replace(/T.*/, ''),
        totalNet: totalNet,
      }
      this.paymentPlanInvoiceData.treeInvoices.push(data);
      // console.log(inv);
    })

    this.assignPaymentPlanInvoices();

    this.loading = false;

    console.log(`document change: loadDocumentTree end`, doc.code)
    this.documentTreeLoadedEvent.emit(this.root);
  }

  public async createDocumentNode(doc: CompilationModel) {
    const docNode = this.findNode(doc.parent);
    const node: IDocumentTree = {
      associated: undefined,
      code: doc.code,
      children: [],
      doc: doc,
    }
    if (docNode && this.root) { // parent exists
      docNode.children.push(node);
    } else { // no parent, initial document
      this.root = node;
    }
    this.node = node;
    this.populateProps(node.doc);

    this.documentTreeLoadedEvent.emit(this.root);
  }

  populateProps(doc: CompilationModel) {

    console.log('populate props');

    this.parent = this.findNode(doc.parent);

    // TODO: combine document tree node with document.component this.selected (compilation)
    this.offer = this.findDocByUpstreamType(doc, 'OF');
    this.order = this.findDocByUpstreamType(doc, 'OR');
    this.frameContract = this.findDocByUpstreamType(doc, 'FCOR');
    this.orderConfirmation = this.findDocByUpstreamType(doc, 'OC');
    this.obic = this.findDocByUpstreamType(doc, 'OBIC')
    const dns = this.findDocsByDownstreamType(doc, 'DN');
    // console.log("==== dns =====", dns);
    this.deliveryNote = this.findDocByUpstreamType(doc, 'DN') || (dns?.length === 1 ? dns[0] : null);
    const invcs = this.findDocsByDownstreamType(doc, 'IN');
    this.invoice = this.findDocByUpstreamType(doc, 'IN') || (invcs?.length === 1 ? invcs[0] : null);

    // console.log('dns, ', dns, doc, this);

    this.combinedShipment = null;
    // if (['CS'].includes(CompilationTypeData.get(doc.type).code) && this.orderConfirmation) {
    if (['OC', 'CS', 'PL', 'DN', 'IN', 'PFIN'].includes(CompilationTypeData.get(doc.type).code) && this.orderConfirmation) {
      const pfspos =
        ((this.orderConfirmation.lineItems && this.orderConfirmation.lineItems.map(li => li.pfspo && li.pfspo.map(spo => spo.doc)) || [])
          .reduce((acc, curr) => curr && [...acc, ...curr], []) || [])
      const distinctPfspos = distinctFromObjectArray(pfspos, 'code') || [];

      this.associatedPfspos = [];
      this.combinedShipments = [];
      const map = {};
      for (const pfspo of distinctPfspos as CompilationModel[]) {
        if (!this.associatedPfspos.map(m => m.code).includes(doc.code)) {
          if (pfspo) this.associatedPfspos.push(pfspo);
          const cs = pfspo && pfspo.transportRun.csDoc;
          if (cs) {
            this.combinedShipments.push(cs);
            if (!map[cs.code]) map[cs.code] = [];
            if (!map[cs.code].includes(pfspo.code)) map[cs.code].push(pfspo.code);
          } else {
            if (!map['']) map[''] = [];
            if (!map[''].includes(pfspo.code)) map[''].push(pfspo.code);
          }
        }
        this.csSpoCodes = map[''] || [];
        for (const i in map) {
          if (i) {
            this.csSpoCodes.push(i, ...map[i]);
          }
        }
        console.log('spo codes', this.csSpoCodes);
        this.combinedShipments = distinctFromObjectArray(this.combinedShipments, 'code') || [];
        // if (this.combinedShipments.length === 1) this.combinedShipment = this.combinedShipments[0];
      }
      doc.packingImages = this.deliveryNote?.packingImages;
      doc.lineItems.forEach((li, idx) => {
        li.poVisibility = this.orderConfirmation.lineItems.find(f => f.position.internalPos === li.position.internalPos)?.poVisibility;
      })
    } else if (['PFSPO', 'SPO'].includes(CompilationTypeData.get(doc.type).code)) {
      this.combinedShipment = this.node.doc.transportRun?.csDoc;
      this.combinedShipments = [this.combinedShipment];

      if (doc.attachToOC && this.parent) {
        doc.lineItems.forEach((li, idx) => {
          li.relatedOCs = [{ parentPos: idx+1, pos: idx+1, doc: this.parent.doc, quantity: li.displayUnitQuantity }];
        })
      }
    }

    console.log('@tree-service: root', this.root);
    console.log('@tree-service: node', this.node);
    console.log('@tree-service: parent', this.parent);
    console.log('@tree-service: linkedOR', this.order);
    console.log('@tree-service: linkedFC', this.frameContract);
    console.log('@tree-service: linkedOC', this.orderConfirmation);
    console.log('@tree-service: linkedDN', this.deliveryNote);
    console.log('@tree-service: linkedIN', this.invoice);
    console.log('@tree-service: linkedObic', this.linkedObic);
    console.log('@tree-service: linkedPfspos', this.associatedPfspos);
    console.log('@tree-service: linkedCS', this.combinedShipment);
    console.log('@tree-service: linkedCSs', this.combinedShipments);

  }


  findNodeByUpstreamType(compilation: CompilationModel, type: string /* CompilationTypeData.code */) {
    if (!compilation) return null;
    let top = this.findNode(compilation.code);
    if (compilation.type === CompilationTypeData.get(type).id) return top;
    do {
      top = top && top.doc && this.findNode(top.doc.parent);
    } while (top && top.doc.type !== CompilationTypeData.get(type).id)
    return top;
  }
  findDocByUpstreamType(compilation: CompilationModel, type: string) {
    const node = this.findNodeByUpstreamType(compilation, type);
    return node && node.doc;
  }
  findNodesByDownstreamType(compilation: CompilationModel, type: string) {
    const node = compilation && this.findNode(compilation.code);
    // console.log('ooo--> dns findNodesByDownstreamType', node);
    const nodes = node && this.filterDocumentTree(type, node); // TODO: this returns docs not nodes!
    // console.log('ooo--> dns type nodes', type, nodes);
    return nodes;
  }
  findDocsByDownstreamType(compilation: CompilationModel, type: string) {
    // const nodes = this.findNodesByDownstreamType(compilation, type);
    const node = compilation && this.findNode(compilation.code);
    // console.log('--> dns nodes', node);
    // const docs = nodes && nodes.map(m => m.doc) || [];
    const docs = node && this.filterDocumentTree(type, node);
    // console.log('--> dns docs', docs);
    return docs;
  }
  findDocsByUpstreamType(compilation: CompilationModel, type: string): CompilationModel[] {
    const docs = [];
    let current = compilation;
    do {
      docs.unshift(current);
      if (current.type === CompilationTypeData.get(type).id) break;
      current = this.findDoc(current.parent);
    } while (current)
    return docs;
  }

  findSubtree(code: string, withCancelled = false) {
    const stack = [ withCancelled ? this._withCancelledRoot : this.root ]
    while (stack.length) {
      const doc = stack.pop()
      if (doc && doc.code === code) return doc
      doc && doc.children && stack.push(...doc.children)
    }
    return null
  }

  findDoc(code: string, withCancelled = false): CompilationModel {
    const subtree = this.findSubtree(code, withCancelled);
    return subtree && subtree.doc;
  }
  findNode(code: string, withCancelled = false): IDocumentTree { // alias for findSubtree
    return this.findSubtree(code, withCancelled);
  }
  filterDocumentTree(docType: string /* CompilationTypeData.code */, root = this.root) {
    // console.log('filterDocumentTree', this.root)
    if (!root) return [];
    const docs = [];
    if (!docType || root && root.doc && root.doc.type === CompilationTypeData.get(docType).id && root.doc.documentStatus !== DocumentStatusData.get('cancelled').id) {
      docs.push(root.doc);
    }
    const stack = [];
    stack.push(...root.children);
    while (stack.length) {
      const leaf = stack.pop();
      // console.log('dns leaf', leaf.doc.documentStatus, leaf.doc.code);
      if (leaf.doc && leaf.doc.type === CompilationTypeData.get(docType).id && leaf.doc.documentStatus !== DocumentStatusData.get('cancelled').id && !docs.find(f => f.code === leaf?.doc?.code)) {
        docs.push(leaf.doc);
      }
      if (leaf.children) stack.push(...leaf.children);
    }
    // console.log('dns docs', docs);
    return docs;
  }
  filterCancelled(root: IDocumentTree = this.root) {
    if (!root || !root.doc || root.doc.documentStatus === DocumentStatusData.get('cancelled').id) return null;
    const tree: IDocumentTree = { code: root.doc.code, doc: root.doc, associated: {}, children: [] }
    tree.associated.pfspos = root.associated && (root.associated.pfspos || []).filter(f => f.documentStatus !== 3 /* cancelled */);
    tree.associated.nrkgPfspc = root.associated && root.associated.nrkgPfspc;
    tree.associated.cs = root.associated && root.associated.cs;
    for (const child of root.children) {
      const ret = this.filterCancelled(child);
      if (ret) tree.children.push(ret);
    }
    return tree;
  }

  getFlatByDocType(topDoc = 'OR', compilation: CompilationModel): CompilationModel[] {
    // console.log('getFlatByDocType', topDoc, compilation?.code, compilation);
    return this.getFlat(this.findNodeByUpstreamType(compilation, topDoc));
  }

  // cachedAssociatedPfspos = [];
  getAssociatedPfspos(type: number, cached = false) {

    if (!this.node || !this.node.doc || !this.node.doc.lineItems) return;
    // console.log('getAssocSpo', this.node.doc);

    if (['FCOR', 'OR'].includes(CompilationTypeData.get(type).code)) {
      const ret = [];
      const ocs = this.filterDocumentTree('OC', this.docNode);
      ocs?.forEach(oc => {
        const ocNode = this.findNode(oc.code);
        const sposa = ocNode.doc.lineItems && ocNode.doc.lineItems.map(li => li.pfspo && li.pfspo.map(m => ( { ...m, oc })));
        const spos = sposa && sposa.reduce((a, c) => c && [...a, ...c], []);
        if (spos) ret.push(...spos);
      });
      return ret;
    } else if (this.obic && DocConfig.get('obicSummary').docs.includes(CompilationTypeData.get(type).code)) {
      const obic = this.findDocByUpstreamType(this.node.doc, 'OBIC') || this.root.doc;
      // const pfspos = this.findNodesByDownstreamType(obic, 'PFSPO') || [];
      // const spos = [...pfspos, ...(this.findNodesByDownstreamType(obic, 'SPO') || [])];

      // console.log('load pfspos, spos');

      const pfspos = this.findDocsByDownstreamType(obic, 'PFSPO') || [];
      const spos = [...pfspos, ...(this.findDocsByDownstreamType(obic, 'SPO') || [])];

      // console.log('====> getAssociatedPfspos', spos);
      const ret = spos.sort((a, b) => a.exwJapanDate < b.exwJapanDate ? -1 : 1).map(m => ({
        doc: m,
        unitQuantity: m.lineItems.reduce((a, c) => a + c.displayUnitQuantity, 0),
        quantity: m.lineItems.reduce((a, c) => a + c.quantity, 0)
      }));
      // console.log('getAssocSpo', this.linkedObic, pfspos, spos, ret);
      return ret;
    } else {
      const sposa = this.node.doc.lineItems
                      && this.node.doc.lineItems.map(li => li.pfspo && li.pfspo.map(m => ({ ...m, oc: this.orderConfirmation })));
      const spos = sposa && sposa.reduce((a, c) => c && [...a, ...c], []);
      return spos || [];
    }
  }

  getRelatedOCs() {
    if (!this.node || !this.node.doc || !this.node.doc.lineItems) return;
    const oca = this.node.doc.lineItems.map(li => li.relatedOCs);
    const ocs = oca && oca.reduce((a, c) => c && [...a, ...c], []);
    return ocs || [];
  }

  getFlat(root: IDocumentTree = this.root) {
    const docs: CompilationModel[] = [];
    const stack = [root];
    while (stack.length > 0) {
      const item = stack.pop();
      if (item && item.children && item.children.length) stack.push(...item.children);
      if (item) docs.push(item.doc);
    }

    return docs;
  }

  getDisplayTree(root = this.root) {
    const ret = [];
    function addLine(subtree: IDocumentTree, level) {
      const space = Array(level).join('-');
      ret.push({
        doc: subtree.doc,
        text: space + ' ' + getDocumentNumber(subtree.doc),
      })
      for (const child of subtree.children) {
        addLine(child, level+1);
      }
    }
    addLine(root, 1);
    return ret;
  }

  getRelatedDocuments(root: CompilationModel) {
    const getText = (doc) => {
      const nr = getDocumentNumber(doc);
      return nr + (doc.issueDate ? ' (' + (new DatePipe('de')).transform(doc.issueDate, 'yyyy-MM-dd') + ')' : '');
    }
    const docs = [{doc: root, text: getText(root) }];
    let parent = this.findDoc(root.parent);
    do {
      if (parent) docs.unshift({ doc: parent, text: getText(parent) });
      parent = parent?.parent && this.findDoc(parent.parent);
    } while (parent);
    
    // subtree
    const addChildren = (parent, level) => {
      const space = Array(level).join('-');
      const children = this.getFlat().filter(d => d.parent === parent.code);
      if (children?.length) {
        docs.push(...children.map(doc => ({ doc: doc, text: space + getText(doc) })));
        children.forEach(ch => addChildren(ch, level+1));
      }
    }
    addChildren(root, 2);
    return docs;
  }

  async synchronizeField(compilation: CompilationModel, field: string, docTypes = ['OC', 'PL', 'DN', 'IN', 'PFIN']) {

    await Promise.all(this.linkedOCTreeDocs.map(async treeDoc => {
      const doc = await this.api.loadCompilationByCode(treeDoc.code);
      doc[field] = compilation[field];
      return this.api.saveCompilation(doc);
    }))
  } 

  async synchronizeSharedFields(compilation: CompilationModel, additionalLogText: string = '') {
    // console.log('synchronizeSharedFields', compilation);
    // const tree = this.tree.getFlatByDocType('OC');
    const type = CompilationTypeData.get(compilation.type).code;

    if (type === 'OC' && this.linkedOC.code !== compilation.code) {
      console.log('tree not in sync !!');
      this.api.debugLog('tree not in sync for ' + compilation.code, {compilation: compilation.code, linkedOC: this.linkedOC})
      return
    }

    // if (compilation.completelyInvoiced && compilation.packingStatus < EPackingStatus.completed) compilation.packingStatus = EPackingStatus.completed;

    const isOcDn = ['OC', 'PL', 'DN'].includes(CompilationTypeData.get(compilation.type).code);

    // console.log('linked OC tree docs', this.linkedOCTreeDocs);

    for (const doc of this.linkedOCTreeDocs) {


      const tmp = await this.api.loadCompilationByCode(doc.code);

      if (!tmp) continue;

      await Promise.all(['vieDispatchDate', 'packingConditions', 'packingText', 'associatedPfspo', 'completelyInvoiced', 'packingImages'].map(async field => {

        const syncDocs = DocConfig.get(field).sync

        // console.log('sync', field);

        if (field === 'completelyInvoiced') {
          if (compilation.type === CompilationTypeData.get('OC').id) {
            if (compilation.completelyInvoiced) {
              if (compilation.packingStatus === EPackingStatus.delivered) compilation.packingStatus = EPackingStatus.completed;
            } else {
              if (compilation.packingStatus === EPackingStatus.completed) compilation.packingStatus = EPackingStatus.delivered;
            }
          } else {
            return; 
          }
        } 

        if (compilation && syncDocs?.includes(type)) {
          if (field == 'associatedPfspo') {
            tmp.lineItems.forEach((li, idx) => {
              tmp.lineItems[idx].pfspo = compilation.lineItems[idx]?.pfspo;
              // console.log('sync line items edit4', li, idx, tmp.lineItems[idx].pfspo)
            })
          } else if (field === 'vieDispatchDate' && isOcDn) {
            tmp.vieDispatchDate = compilation.vieDispatchDate;
            tmp.transferTime2 = Math.abs(moment(compilation.etaCustomerDate).diff(moment(compilation.vieDispatchDate), 'days'));
            // tmp.dateOfDispatch = compilation.vieDispatchDate;
            if (compilation.nextInvoiceDateAttached) tmp.expectedNextInvoiceDate = compilation.vieDispatchDate;
            // console.log('--->update transferTime2', tmp.code, compilation.transferTime2, '->', tmp.transferTime2);
            // this.api.debugLog(`synchronizeSharedFields ${compilation.code}`, {
            //   doc: doc.code,
            //   vieDispatchDate: `${tmp.vieDispatchDate}`,
            //   transferTime2: `${tmp.transferTime2}`,
            // })
          } else if (JSON.stringify(tmp[field]) !== JSON.stringify(compilation[field])) {
            // console.log(`sync field ${field}: from ${tmp[field]} to ${compilation[field]}`, tmp[field], compilation[field])
            if (!['packingConditions','associatedPfspo'].includes(field)) await Changelog.addAutolog(tmp, this.auth.user, this.api, [{ 
              quickText: `sync field: ${field}${additionalLogText}`, 
              text: `from ${JSON.stringify(tmp[field])} to ${JSON.stringify(compilation[field])} in ${JSON.stringify(syncDocs)?.replace(/,"/g, ', ')?.replace(/"/g, '')}` 
            }])
            tmp[field] = compilation[field];
          }
          // console.log('--sync docs5', tmp[field], tmp)
        }

      }));

      compilation.changelog = tmp.changelog; // sync changelog
      await this.api.saveCompilation(tmp)
    }
    // TODO: dirty check
    // ['vieDispatchDate', 'transferTime2', 'packingConditions', 'associatedPfspo', 'completelyInvoiced','packingStatus'].forEach(field => {
    //   const syncDocs = DocConfig.get(field).sync
    //   // console.log('synchronizeSharedFields2', syncDocs);

    //   if (compilation && syncDocs && syncDocs.includes(type)) {
    //     syncDocs.forEach(sf => {
    //       const doc = this.linkedOCTreeDocs.filter(f => f.type === CompilationTypeData.get(sf).id);
    //       console.log('sync docs3', sf, field, compilation[field], field == 'associatedPfspo', doc);
    //       doc.forEach(async el => {
    //         const tmp = await this.api.loadCompilationByCode(el.code);
    //         if (field == 'associatedPfspo') {
    //           tmp.lineItems.forEach((li, idx) => {
    //             tmp.lineItems[idx].pfspo = compilation.lineItems[idx].pfspo;
    //             // console.log('sync line items edit4', li, idx, tmp.lineItems[idx].pfspo)
    //           })
    //         } else {
    //           tmp[field] = compilation[field];
    //         }
    //         tmp.transferTime2 = Math.abs(moment(compilation.etaCustomerDate).diff(moment(compilation.vieDispatchDate), 'days'));
    //         console.log('--->update transferTime2', compilation.code, compilation.transferTime2, '->', tmp.transferTime2);
    //         // console.log('--sync docs5', tmp[field], tmp)
    //         this.api.saveCompilation(tmp)//.then((doc) => console.log('----sync docs6', field, doc[field], doc));
    //       })
    //     })
    //   }
    // });

  }

  getItemOrderPos(item) {
    const li = this.linkedOC?.lineItems?.find(f => f.position?.internalPos === item?.position?.internalPos) || this.linkedOC?.lineItems[0];
    const idx = this.linkedOR?.lineItems?.findIndex(f => f?.position?.internalPos === li?.position?.orderPos);
    // console.log('getItemOrderPos', this.linkedOC.code, this.linkedOR.code, item?.position, item, li.position, idx);
    return (idx !== null && idx >= 0) ? idx + 1 : ''; 

    // const idx = this.linkedOR?.lineItems?.findIndex(f => f?.position?.internalPos === item?.position?.orderPos);
    // return (idx !== null && idx >= 0) ? idx + 1 : ''; 
  }

  assignCompilationModel(tree: IDocumentTree) {

    if (!tree) return;
    
    const doc = new CompilationModel();
    Object.assign(doc, tree.doc);
    tree.doc = doc;

    tree.children.forEach(el => this.assignCompilationModel(el));

    return tree;
  }

  assignPaymentPlanInvoices() {

    for (const doc of this.getFlat()) {
      this.injectPaymentPlanInvoicesNet(doc);
    }
  }

  injectPaymentPlanInvoicesNet(doc: CompilationModel) {

    for (let ppIn of doc.paymentPlan?.items?.flatMap(m => m.invoices)) {
      if (ppIn) {
        const inv = this.invoices?.find(f => f.code === ppIn.code);
        if (inv) {
          ppIn.code = inv.code;
          ppIn.paymentType = inv.invoicePayment;
          ppIn.issueDate = inv.issueDate;
          ppIn.baseProduct = null;
          ppIn.net = -inv.sumTotal;
          ppIn.paymentStatus = inv.invoiceStatus;
          ppIn.paymentDate = inv.paymentDate;
        }
      }
    }
  }

}
